import React from "react";
import ReactGa from "react-ga";

const ContactLink = (props) => {

        if(props.hrefLink) {

            ReactGa.event({
                category: 'Annotation Contact Link / Download',
                action: `User clicked on ${props.contactName} with link to ${props.hrefLink}`,
            })

            return (

                <div style={{display: "inline"}}>
                    <a dangerouslySetInnerHTML={{__html: props.contactName}}
                        href={props.hrefLink}
                       target="_blank"
                       onClick={props.onClickHandler}>
                        {/*{props.contactName}*/}
                    </a>
                </div>
            )
        }
        return null;
}

export default ContactLink;