import React, {Component} from "react";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const IMAGE_SRC_PATH = process.env.PUBLIC_URL + '/paintings/Thumbnails/';

export default class VideoDisplayerComponent extends Component {

    constructor(props) {
        super(props);

        this.onClickThumbnail = this.onClickThumbnail.bind(this);

        // console.log("[VideoDisplayerComponent]", this.props.videoData.thumbnailUrl);
        this.state = {
            renderTextFlag: this.props.videoData.headerText
                || this.props.videoData.artistName || this.props.videoData.moreInfo || this.props.videoData.bodyText
                || this.props.videoData.datePublished
        }
    }

    onClickThumbnail = (videoDataObj) => {
        // window.alert("Play Video:" + videoDataObj.videoId);
        this.props.onClickThumbnail(videoDataObj)
    }

    render() {
        // console.log("[VideoDisplayerComponent]", this.props.videoData);
        let rowStyle = this.props.index !== (this.props.noOfObjects - 1) ? "video-displayer-rows" : "video-displayer-last-row";
        // let textAlignment = ()
        return (
            <Container className="video-displayer-container">
                <a onClick={() => this.onClickThumbnail(this.props.videoData)} style={{width: "100%"}}>
                    <Row className={rowStyle} g-0="true">
                        <Col xs={12} md={4}>
                            <div className="video-displayer-container">
                                <Image className="video-displayer-image"
                                       width="100%"
                                       src={process.env.PUBLIC_URL + `${this.props.videoData.thumbnailUrl}`}
                                       alt="Thumbnail Image"
                                       fluid={true}
                                />
                            </div>
                        </Col>

                        <Col className="text-left" xs={12} md={8}>
                            <Row
                                className="video-displayer-text-row"><b>{this.props.videoData.headerText}</b>
                            </Row>
                            {/*<Row*/}
                            {/*    className="video-displayer-text-row"><i>{this.props.videoData.artistName}</i></Row>*/}
                            {/*<Row className="video-displayer-text-row"><i>{this.props.videoData.moreInfo}</i></Row>*/}
                            {/*<Row*/}
                            {/*    className="video-displayer-text-row video-displayer-text-row-body-text">{this.props.videoData.bodyText}</Row>*/}
                            {/*<Row*/}
                            {/*    className="video-displayer-text-row"><i>{this.props.videoData.datePublished}</i></Row>*/}
                        </Col>
                    </Row>
                </a>
            </Container>
        )
    }
}