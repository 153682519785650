import {Color} from "three";
import * as THREE from "three";

function generateTexture() {

    const canvas = document.createElement( 'canvas' );
    canvas.width = 2;
    canvas.height = 2;

    const context = canvas.getContext( '2d' );
    context.fillStyle = 'white';
    context.fillRect( 0, 1, 2, 1 );

    return canvas;

}

//const texture = new THREE.CanvasTexture( generateTexture() );

export const MATERIAL_PROPS_DB = [


    {
        materialName: "GlassMat",
        glass: true,
        glassMatFunc: (envMap) => {
            return new THREE.MeshPhysicalMaterial({
                name: `GlassMat-MPM`,
                color: new THREE.Color(1.0, 1.0, 1.0),
                metalness: 0,
                roughness: 0,
                // alphaMap: texture,
                alphaTest: 0.5,
                envMap: envMap, //this.backgroundMap,
                envMapIntensity: .5,
                depthWrite: false,
                transmission: 0.7, // use material.transmission for glass materials
                opacity: .52, // set material.opacity to 1 when material.transmission is non-zero
                transparent: true,
                ior: 1.5,
                specularIntensity: 1,
                specularColor: 0xffffff,
                side: THREE.DoubleSide,
                //alphaMap: texture,
            });
        },
    },

    {
        materialName:"I_UV_01",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_02",
        toneMapped:'N',

    },
    {
        materialName:"I_UV_03",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_04",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_05",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_06",
        toneMapped:'N',
    },

    {
        materialName:"I_UV_07",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_08",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_09",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_10",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_11",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_12",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_13",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_14",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_15",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_16",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_17",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_18",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_19",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_20",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_21",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_22",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_23",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_24",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_25",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_26",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_27",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_28",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_29",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_30",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_31",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_32",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_33",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_34",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_35",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_36",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_37",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_38",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_39",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_40",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_41",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_42",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_43",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_44",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_45",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_46",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_47",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_48",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_49",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_50",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_51",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_52",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_53",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_54",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_55",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_56",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_57",
        toneMapped:'N',
    },
    {
        materialName:"I_UV_58",
        toneMapped:'N',
    },

    {
        materialName:"PanelsModelsrMat",
        aOIntensity:0.8839813893115712,
        envMapIntensity:4.372362539744721,
    },

    {
        materialName:"WallsModelsPanelsFloorMat",
        aOIntensity:1.107504547184254,
        envMapIntensity:3.1057313117995187,
    },

    {
        materialName:"WallMat",
        aOIntensity:0.7,
        envMapIntensity:0.8,
    },
    {
        materialName:"RenderedHDRIMat",
        toneMapped:'N',
    },

    {
        materialName:"WallTopPanelEmmisiveLights",
        toneMapped:'N',
    },


    {
        materialName:"WallTopPanelsMat",
        aOIntensity:0.6,
        // envMapIntensity:3.1057313117995187,
    },




    /*
[
{
 materialName:"WallMat",
aOIntensity:0.7881857502232785,
envMapIntensity:0.8,
 },
{
 materialName:"WallsModelsPanelsFloorMat",
aOIntensity:1.107504547184254,
emmisiveColor:
 new Color( 0, 0, 0 ),
emmissiveIntensity:1,
envMapIntensity:3.1057313117995187,
lightMapIntensity:1,
roughness:1,
toneMapped:true,
color:
 new Color( 1, 1, 1 ),
 },

 ]
    */



]
