import * as THREE from "three";
import {CAMERA_LOCATION_OFFSET} from "../../../client-data/clientOptions";

export type UpdatePlayerCallBack = ((newPos: THREE.Vector3,index:number,length:number, pathSegmentEndPos:THREE.Vector3, distance: number, meshTransform?: MeshTransform) => void) | null;
export type CompletePlayerCallBack = ((meshTransform: MeshTransform, distance: number) => void) | null;

export type MeshTransform = {
    rotationEuler:THREE.Euler,
    position:THREE.Vector3,
    rotationQuaternion:THREE.Quaternion,
    node?: THREE.Object3D;
}

export type MeshTransformQuaternion = {
    rotation:THREE.Quaternion,
    position:THREE.Vector3
}

function getChildNodeFromGroup (targetNode: THREE.Object3D):THREE.Object3D  {
    let node = targetNode;
    if (targetNode.parent && targetNode.parent.type === 'Group' && targetNode.parent.userData.painting_id ) {
        const parentUserId = targetNode.parent.userData.painting_id;
        const childUserId = targetNode.userData.painting_id;

        if (parentUserId === childUserId) {
            node = targetNode.parent;
        }
    }
    return node;
}

export function getNavigationTargetPosition (targetNode: THREE.Object3D):MeshTransform  {

    let node = getChildNodeFromGroup(targetNode);

    const wd1 = new THREE.Vector3();
    const wPos = new THREE.Vector3();
    node.getWorldPosition(wPos);
    node.getWorldDirection(wd1);

    const cameraPosition = wPos.clone().addScaledVector(wd1,  CAMERA_LOCATION_OFFSET);
    return {
        rotationEuler: node.rotation.clone(),
        position: cameraPosition,
        rotationQuaternion:node.quaternion.clone(),
        node:node
    }
}
