import * as THREE from "three";
import {Euler, Vector3} from "three";
import {ANNOTATION_LOCATION} from "./GlobalConstants";
import {isMobile as reactDetectIsMobile} from "react-device-detect";


let clientOptions_generic = null;
let DEFAULT_ANNOTATION_SCALE_GENERIC = null;
let CAMERA_LOCATION_OFFSET_GENERIC = 2.5;

if (reactDetectIsMobile) {
    clientOptions_generic = {
        useGUI: 'N',
        initCameraOrient: {
            position: new Vector3(-8.4, 1.52, -20.765),
            rotation:new Euler(3.141, -.136, 3.141, 'XYZ'),
        },
        gammaOutput: 'N', //ignore for now
        gammaInput: 'Y', //ignore for now
        usePaintingsIdForEncoding: 'N', //if 'Y', then apply  paintingTextureEncoding setting to each painting tagged with painting_id
        exposure: 0.73,
        textureEncoding: 'sRGB',  //'Linear', //'sRGB',
        paintingTextureEncoding: 'sRGB', //'Linear', //'sRGB',
        physicallyCorrectLights: 'Y', //if not presented in the Viewer.js then must be set to 'Y'
        fov: 45, //Camera field of view
        outputEncoding: 'sRGB', //'Linear' or 'sRGB',
        envMapIntensity: 1.59, // if envMap is not used (colorMap = 0) then this property has nos no effect
        toneMapping:  'ACESFilmicToneMapping', //  string can be one of these values (default is NoToneMapping ) ['NoToneMapping', 'LinearToneMapping','ReinhardToneMapping','CineonToneMapping','ACESFilmicToneMapping']
        envMap: {
            colorMap: 8, //envmap for light. Can be Cubemap or HDR - get it from the index of the array in /environment/index.js
            backgroundMap: 0, //envmap for background image. Can be Cubemap or HDR - get it from the index of the array in /environment/index.js
        },
        usePostprocessing: 'N',

        // This is the mobile's version
        modelName:'sony_swpa2022_045_etc1s_mobile.glb',

    };


    DEFAULT_ANNOTATION_SCALE_GENERIC = new THREE.Vector3(.08, .08, 1);
    CAMERA_LOCATION_OFFSET_GENERIC = 2.5;
}
else {
    clientOptions_generic = {
        useGUI: 'N',
        initCameraOrient: {
            position: new Vector3(-8.4, 1.52, -20.765),
            rotation:new Euler(3.141, -.136, 3.141, 'XYZ'),
        },
        gammaOutput: 'N', //ignore for now
        gammaInput: 'Y', //ignore for now
        usePaintingsIdForEncoding: 'N', //if 'Y', then apply  paintingTextureEncoding setting to each painting tagged with painting_id
        exposure: 0.73,
        textureEncoding: 'sRGB',  //'Linear', //'sRGB',
        paintingTextureEncoding: 'sRGB', //'Linear', //'sRGB',
        physicallyCorrectLights: 'Y', //if not presented in the Viewer.js then must be set to 'Y'
        fov: 45, //Camera field of view
        outputEncoding: 'sRGB', //'Linear' or 'sRGB',
        envMapIntensity: 1.59, // if envMap is not used (colorMap = 0) then this property has nos no effect
        toneMapping:  'ACESFilmicToneMapping', //  string can be one of these values (default is NoToneMapping ) ['NoToneMapping', 'LinearToneMapping','ReinhardToneMapping','CineonToneMapping','ACESFilmicToneMapping']
        envMap: {
            colorMap: 8, //envmap for light. Can be Cubemap or HDR - get it from the index of the array in /environment/index.js
            backgroundMap: 0, //envmap for background image. Can be Cubemap or HDR - get it from the index of the array in /environment/index.js
        },
        usePostprocessing: 'N',

        // This is Desktop's version
        modelName:'sony_swpa2022_045.glb',

    };

    DEFAULT_ANNOTATION_SCALE_GENERIC = new THREE.Vector3(.08, .08, 1);
}





console.log("Client option app type=",window.isMobile,  reactDetectIsMobile);
export  const clientOptions = clientOptions_generic;

export const DEFAULT_ANNOTATION_SCALE = DEFAULT_ANNOTATION_SCALE_GENERIC;

// export const DEFAULT_ANNOTATION_LOCATION = ANNOTATION_LOCATION.TOP_RIGHT; // can be one of ANNOTATION_LOCATION. Default is TOP_LEFT
export const DEFAULT_ANNOTATION_LOCATION = ANNOTATION_LOCATION.TOP_RIGHT; // can be one of ANNOTATION_LOCATION. Default is TOP_LEFT

export const DEFAULT_ANNOTATION_LOCATION_VERT_OFFSET = 0.02 // in meters. vertical offset. default is 0.0

// export const DEFAULT_ANNOTATION_LOCATION_HORIZ_OFFSET = .05 // in meters. Horizontal offset. default is 0.0
export const DEFAULT_ANNOTATION_LOCATION_HORIZ_OFFSET = 0.08 // in meters. Horizontal offset. default is 0.0

export const DEFAULT_ANNOTATION_LOCATION_Z_OFFSET = 0.05 // in meters. Z offset. default is 0.1

//Rana comments: this decides the size of the annotation buttons in 3D world. Don't change the z-axes
// export const DEFAULT_ANNOTATION_SCALE = new THREE.Vector3(.08, .08, 1);// export const DEFAULT_SPRITE_IMAGE_URL = '/assets/sprites/AnnotationsI.svg'

// export const DEFAULT_ANNOTATION_SCALE = new THREE.Vector3(.05, .05, 1);


export const DEFAULT_SPRITE_IMAGE_URL = '/assets/sprites/project_sprites/Info.svg';
export const SPRITE_TONEMAPPED = false; //will effect the custom sprite defined in AnnotationDB
export const SPRITE_COLOR = 0xffffff;//0x999999; // RGB color - will effect the custom sprite defined in AnnotationDB
export const SPRITE_TEXTURE_ENCODING = THREE.sRGBEncoding; //THREE.LinearEncoding;   //will effect the custom sprite defined in AnnotationDB

export const CAMERA_LOCATION_OFFSET = CAMERA_LOCATION_OFFSET_GENERIC; //how far camera will offset when using camera hoping -in the direction normal to the target. Applies only to locations assigned in Blender with 'cameraLocationId'
export const CAMERA_HEIGHT_CONSTRAINT = true; //will set hopping camera to the initial y-axis of the  clientOptions.initCameraOrient.position

export const ZONE_LINKS_LABEL = 'Getting Around';
export const VIDEO_DISPLAYER_LIST = 'SELECT VIDEO';
export const EXTERNAL_LINK_LIST_HEADER = `EXTERNAL LINK HEADER HERE`;
// export const ZONE_HYPERLINKS_ALIGNMENT = {textAlign: "center", fontFamily: "Times New Roman, Times, Serif"};
export const ZONE_HYPERLINKS_ALIGNMENT = {textAlign: "left"};
export const TELEPORTPOINT_DISTANCE_RATIO = 0.5;
export const COLLIDER_DISTANCE_RATIO = .75;

export const DISPLAY_ZONE_LINK = true; //show hoping icon in the menu
export const DISPLAY_AUDIO_BUTTON = false;
export const HELP_COMPONENT = true; //Show Help Modal once loading the App
export const DISPLAY_ANNOTATION_TOGGLE_MENU_ITEM = true;
export const DISPLAY_ALL_CONTROLS_TOGGLE_MENU_ITEM = true;
export const DISPLAY_EXTERNAL_LINKS = false;
export const DISPLAY_TOAST_NOTIFICATION = (reactDetectIsMobile) ? false : false;

export const TOAST_NOTIFICATION_TEXT = `For HINTS & TIPS Click  on '?' Icon`;

export const DEFAULT_VIDEO_PLAY_SPRITE_IMAGE_URL = '/assets/sprites/videoPlayButtonBlack.svg';
export const  DEFAULT_VIDEO_PAUSE_SPRITE_IMAGE_URL = '/assets/sprites/videoPauseButtonBlack.svg';
export const VIDEO_SPRITE_TONEMAPPED = false; //will effect the custom sprite defined in AnnotationDB
export const VIDEO_SPRITE_COLOR = 0xaaaaaa;//0x999999; // RGB color - will effect the custom sprite defined in AnnotationDB
export const VIDEO_SPRITE_TEXTURE_ENCODING = THREE.LinearEncoding; //THREE.LinearEncoding;   //will effect the custom sprite defined in AnnotationDB

export const DEFAULT_VIDEO_ANNOTATION_LOCATION = ANNOTATION_LOCATION.CENTER_RIGHT; // can be one of ANNOTATION_LOCATION. Default is TOP_LEFT

export const DEFAULT_VIDEO_ANNOTATION_LOCATION_VERT_OFFSET = .01 // in meters. vertical offset. default is 0.0

export const DEFAULT_VIDEO_ANNOTATION_LOCATION_HORIZ_OFFSET = .08 // in meters. Horizontal offset. default is 0.0

export const DEFAULT_VIDEO_ANNOTATION_LOCATION_Z_OFFSET = 0.1 // in meters. Z offset. default is 0.1

//Rana comments: this decides the size of the annotation buttons in 3D world. Don't change the z-axes
// export const DEFAULT_VIDEO_ANNOTATION_SCALE = new THREE.Vector3(.15, .15, 1);// export const DEFAULT_SPRITE_IMAGE_URL = '/assets/sprites/AnnotationsI.svg'
export const DEFAULT_VIDEO_ANNOTATION_SCALE = new THREE.Vector3(.15, .15, 1);// export const DEFAULT_SPRITE_IMAGE_URL = '/assets/sprites/AnnotationsI.svg'


export const DEFAULT_AUDIO_PLAY_SPRITE_IMAGE_URL = '/assets/sprites/audioListenHereGreen.svg';
export const  DEFAULT_AUDIO_PAUSE_SPRITE_IMAGE_URL = '/assets/sprites/videoPauseButton.svg';

export const DEFAULT_AUDIO_ANNOTATION_LOCATION = ANNOTATION_LOCATION.CENTER; // can be one of ANNOTATION_LOCATION. Default is TOP_LEFT

export const DEFAULT_AUDIO_ANNOTATION_LOCATION_VERT_OFFSET = 0 // in meters. vertical offset. default is 0.0

export const DEFAULT_AUDIO_ANNOTATION_LOCATION_HORIZ_OFFSET = 0 // in meters. Horizontal offset. default is 0.0

export const DEFAULT_AUDIO_ANNOTATION_LOCATION_Z_OFFSET = 0.25 // in meters. Z offset. default is 0.1

//Rana comments: this decides the size of the annotation buttons in 3D world. Don't change the z-axes
export const DEFAULT_AUDIO_ANNOTATION_SCALE = new THREE.Vector3(.25, .25, 1);// export const DEFAULT_SPRITE_IMAGE_URL = '/assets/sprites/AnnotationsI.svg'


export const DELTA_FORWARD_ACCEL = 0.1 //inc in the acceleration of the forward key when moving
export const MAX_KEY_FORWARD_ACCEL = 5  //max inc in the acceleration of the forward key when moving
export const MAX_KEY_TIME_LAPS = 2; //max time in second since last key stroke (up, down left and right arrows) - it will reset the Key acceleration after  MAX_KEY_TIME_LAPS of no key activity

export const ENABLE_VERTICAL_NAVIGATION = false;

export const JUMP_TO_POSITION_USING_ZONE_HOPPING = false;

export const RENDER_ON_REQUEST = true; //only render when needed if true.

export const DEFAULT_MESH_BUTTON_TYPE = 1; // base on MeshButtonType in ButtonTypes.ts (can't use enum in javascript therefore need to use order numbers)

export const DEFAULT_NAVMESH_RAYTRACE_HEIGHT = 3.0;

export const DEFAULT_HOVER_OVER_COLOR = '#00FF00'; //for button hover over color

export const NAVMESH_NAVIGATION_SPEED = 6; //speed of camera movement when navigation

export const MAX_SQUARE_DISTANCE_FOR_ROTATION = 5*5; //max square distance for before navmesh make rotation

export const CAMERA_OFFSET_FROM_NAVMESH = 1.5; // how high the camera from ground

export const MIN_SQUARE_DISTANCE_TO_NAVMESH_NODE = 0.4 * 0.4
