

const ExternalLinksDB = [

    {
        linkId: 'D01CHR01',
        linkTitle: 'Workshop 1 in Heart Failure Nursing',
        linkHref: `https://us06web.zoom.us/j/82422380469?pwd=MlYxTVZJSHR2Zk5ISHpVL3h3YzNZQT09`,
    },
    {
        linkId: 'D01CHR02',
        linkTitle: 'Workshop 2 in Heart Failure Research',
        linkHref: `https://us06web.zoom.us/j/81145598760?pwd=Q2F6WDZuYmNkT0JWN2hJbTMvSDhOUT09`,
    },
    {
        linkId: 'D01CHR03',
        linkTitle: 'Workshop 3 in Heart Failure Education',
        linkHref: `https://us02web.zoom.us/j/83003748945?pwd=YXU4ZnRjL2dXZkFCQkR2dVQ1MEZPdz09`,
    },
    {
        linkId: 'D01CHR04',
        linkTitle: 'Workshop 4 in Heart Failure Policy & Media',
        linkHref: `https://us02web.zoom.us/j/85045062804?pwd=NXM3bWo4Z0dZam15eGpwWE12VngxZz09`,
    },
    // {
    //     linkId: 'D02CHR01',
    //     linkTitle: 'Chat Room',
    //     linkHref: `https://us02web.zoom.us/j/82032158764?pwd=elR4K1ZJbFJIN0JsTkR3UkdyZ1ZEQT09`,
    // },

];

export {ExternalLinksDB};