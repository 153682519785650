// import {
//     ANNOTATION_VIDEO_DISPLAY,
//     ANNOTATION_PARENT,
//     VIDEO_PLAYING_MODE,
//     ANNOTATION_LOCATION,
//     VIDEO_SRC_TYPE
// } from './GlobalConstants';
// import * as THREE from 'three';

export const ANNOTATION_CONTACT_TEXT = '';
export const CONTACT_URL = 'WEBSITE';
export const YOUTUBE = 'YOUTUBE';
export const INSTAGRAM = 'INSTAGRAM';
export const SPOTIFY = 'SPOTIFY';
export const TWITTER = 'TWITTER';
export const TIKTOK = 'TIKTOK';
export const IMDB = 'IMDB';
export const FACEBOOK = 'FACEBOOK';
export const ENQUIRE_EMAIL = 'ENQUIRE';
export const LINKED_IN = 'LINKEDIN';
export const HAS_MEDIA_CAROUSEL_LINK = 'MORE';
export const SEND_TO_EMAIL = 'SEND TO';
export const CANTALOUPE_IMAGE_SERVER = "https://iiif.v21artspace.com/iiif/2/swpa%2F2022%2F";
export const ANNOTATION_TEXT_ALIGNMENT = "left";
export const ANNOTATION_TEXT_LINE_HEIGHT = "1.5";
export const GALLERY_LINK_LABEL = "TROUBLESHOOTING";


const AnnotationsDB = [
    {
        paintingId:"ARCDB005",
        videoId:"",
        videoURL: "",
        iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}ARCDB001.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCDB002.jpg/info.json`,
                    `${CANTALOUPE_IMAGE_SERVER}ARCDB003.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCDB004.jpg/info.json`,
                    `${CANTALOUPE_IMAGE_SERVER}ARCDB005.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCDB006.jpg/info.json`,
                    `${CANTALOUPE_IMAGE_SERVER}ARCDB007.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCDB008.jpg/info.json`],
        headerText:"<h5>Domagoj Burilović</h5>",
        
        "BodyTextTitle":"Dorf",
        bodyText:"<i>Dorf</i><br><br>‘Dorf’ is the German word for village. In the 19th century, the Croatian region of Slavonia was inhabited by people from all nations of the Austro-Hungary empire. A fast economic development began with the exploitation of forest and land. Villages became an elementary demographic unit. German colonists made the largest cultural impact through language, crafts and architecture. Instead of building with mud, people started to build with baked bricks – this raised the quality of life. The irony of history is that today, due to the impact of the war in Croatia and subsequent industry decline, the population is leaving Slavonia for Germany, in search of a better life. With the extinction of the village, the historic houses that became part of its cultural identity are the first to decay. These photographs are a photo montage of houses and local nature. Exploitation of nature was the reason these houses came into being – now this very nature is destroying them.",
        displayMesh:"Y",
        useMeshButton:"N"},

        {
            paintingId:"ARCJA013",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}ARCJA009.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCJA010.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ARCJA011.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCJA012.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ARCJA013.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCJA014.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ARCJA015.jpg/info.json`],
            headerText:"<h5>Javier Arcenillas</h5>",
            "BodyTextTitle":"Nur-Sultan",
            bodyText:"<i>Nur-Sultan</i><br><br>Kazakhstan renamed its capital Nur-Sultan in honour of its former president, Nursultan Nazarbayev, who resigned in 2019 after holding power for three decades. Formerly known as Akmola ('white tomb') and then later Astana ('the capital'), the city was first designated as the capital by Nazarbayev in 1997, replacing Almaty. Until then, it was only a remote corner of the former USSR, a region known for its icy climate and for hosting one of Stalin's notorious Gulags. Conceived by Nazarbayev, Nur-Sultan was designed to befit a country rich in minerals and oil. He recruited the talents of renowned architects such as Sir Norman Foster and over time constructed a city of concrete and glass, full of impressive futuristic buildings, huge shopping centres and enviable sports halls. What was once a forgotten and inhospitable territory is today a city of 800,000 inhabitants, as incredible and eccentric as any of its wealthier neighbours.",
            displayMesh:"Y",
            useMeshButton:"N"
        },
        {
            paintingId:"ARCYC020",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}ARCYC016.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCYC017.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ARCYC018.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCYC019.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ARCYC020.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ARCYC021.jpg/info.json`,],
            headerText:"<h5>Yun Chi Chen</h5>",
            "BodyTextTitle":"Blueprint",
            bodyText:"<i>Blueprint</i><br><br>In 1842, Sir John Herschel discovered the cyanotype. At a time when printing techniques were still developing, the cyanotype was the most efficient and stable printing method.Traditionally, the cyanotype was used in blueprints and to produce photographic prints. In this work, I imitate the process of creating a cyanotype by using digital post-production techniques to retrace the outlines of the buildings. The result is a multi-layered image resembling an architectural blueprint.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"CREAS024",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}CREAS022.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}CREAS023.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}CREAS024.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}CREAS025.jpg/info.json`],
            headerText:"<h5>Alnis Stakle</h5>",
            "BodyTextTitle":"Mellow Apocalypse",
            bodyText:"<i>Mellow Apocalypse</i><br><br>I am interested in the fate of canonised artistic, scientific and journalistic images and their potential to embody contemporary meanings. For my collages, I have used images from the open source collections at art museums, scientific institutions and image banks, whose archives may be considered iconic testimonies of the present and the past. The collages are grounded in my search for syntactic visual language connections pertaining to various periods, media and domains of visual culture. The collages make use of the ideas and technical codes established in visual communication that transcend the borderlines of ages, media and cultures:the codes that are so deeply ingrained in culture that they are used without thinking, and are understood through pre-existing schemas in the recipients' minds.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"CRERN029",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}CRERN026.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}CRERN027.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}CRERN028.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}CRERN029.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}CRERN030.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}CRERN031.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}CRERN032.jpg/info.json`],
            headerText:"<h5>Raphaël Neal</h5>",
            "BodyTextTitle":"New Waves",
            bodyText:"<i>New Waves</i><br><br>This is a collection of teenagers’ portraits, paired with landscapes and inspired by global warming issues. Heatwaves, wildfires or floods are evoked in a cinematic way. The presence of teens – including several young activists – implies the growing threats that the next generation will have to deal with because of climate change, and its inevitable consequences on their physical and mental health. ‘Danger’ and ‘beauty’ were the two words I kept in mind while producing the series: it had to look both engaging and uncomfortable. A contradiction that reminded me of how we have recently been astonished by dangerously high summer temperatures, while accommodating ourselves to them. I also wanted to show different attitudes of today's youth: some look defiant and strong, but others are frightened. I didn’t want to show this ultra-combative youth that exists only in discourses and advertisements.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {paintingId:"CRESG036",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}CRESG033.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}CRESG034.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}CRESG035.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}CRESG036.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}CRESG037.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}CRESG038.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}CRESG039.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}CRESG040.jpg/info.json`],
            headerText:"<h5>Sarah Grethe</h5>",
            bodyText:"<i>Sometimes the Sky Above us is Open.</i><br><br>This work examines the concept of home. I accompanied my mother's return to the place where she grew up: a farm in southern Germany.<br><br>We searched for the past in the present. In the confrontation of an idealised view, tensions between familiarity and strangeness, constancy and change became visible. They demonstrate how home and origin can be both supportive and constricting. The title is a quote from a letter my mother sent to her parents in 1999. When she found it again, while doing research for this project, she couldn't believe what she had written. Narratives of a time that will never return like this emerged. How does home change for a person when they haven't lived there for a long time? And when they return?",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"DOCJG048",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}DOCJG041.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCJG042.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCJG043.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCJG044.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCJG045.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCJG046.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCJG047.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCJG048.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCJG049.jpg/info.json`],
            headerText:"<h5>Jan Grarup</h5>",
            "BodyTextTitle":"The Children of the Financial Collapse in Venezuela",
            bodyText:"<i>The Children of the Financial Collapse in Venezuela</i><br><br>More than 8.5 million people in Colombia urgently need help. The financial collapse in Venezuela has left many with no access to emergency aid, shelter, clean drinking water or food. Children pay the highest price.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {
            paintingId:"DOCFR056",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}DOCFR050.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCFR051.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCFR052.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCFR053.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCFR054.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCFR055.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCFR056.jpg/info.json`],
            headerText:"<h5>Fabian Ritter</h5>",
            "BodyTextTitle":"The Long Days of Hanau",
            bodyText:"<i>The Long Days of Hanau</i><br><br>The racist attack of 19 February 2020 in Hanau, Hessen, shook German society. This photo essay accompanies the friends and relatives of those tragically murdered. In the weeks and months following the attack, while an entire city was still reeling from the shock, solidarity groups and initiatives began forming to support the survivors of the attack. Mourning developed into anger and the desire for change. These photographs provide intimate access to the families and friends affected, who have all lost important people in their lives.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {paintingId:"DOCWM059",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}DOCWM057.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCWM058.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCWM059.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCWM060.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCWM061.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCWM062.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCWM063.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}DOCWM064.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}DOCWM065.jpg/info.json`],
            headerText:"<h5>Win McNamee</h5>",
            "BodyTextTitle":"Insurrection",
            bodyText:"<i>Insurrection</i><br><br>Following a ‘Save America’ rally held by President Donald Trump, a mob of thousands of his supporters descended on the US Capitol and fought their way into the building on 6 January 2021. Members of the mob were called to action by Trump and urged to “fight like hell” after months of disinformation disputing the results of the 2020 presidential election.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"ENVSK072",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}ENVSK066.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVSK067.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ENVSK068.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVSK069.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ENVSK070.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVSK071.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ENVSK072.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ENVSK073.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVSK074.jpg/info.json`],
            headerText:"<h5>Shunta Kimura</h5>",
            "BodyTextTitle":"Living in the Transition",
            bodyText:"<i>Living in the Transition</i><br><br>I photographed these pictures in Gabura Union, Bangladesh between the beginning of October and late November 2021.<br><br>Gabura Union is located on the southwestern coast of Bangladesh. It is one of the most vulnerable areas to the impacts of climate change, and many residents often suffer from its effects. These include river erosion, landslides, rising salinity levels in fresh water sources and collapsing infrastructure, caused by the tropical cyclones that occur frequently.<br><br>The purpose of this photo essay is to capture and communicate the situation for people living quietly in this transition, impacted by climate change.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"ENVGM076",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}ENVGM075.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVGM076.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ENVGM077.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ENVGM078.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVGM079.jpg/info.json`],
            headerText:"<h5>Gideon Mendel</h5>",
            "BodyTextTitle":"Portraits in Ashes",
            bodyText:"<i>Portraits in Ashes</i><br><br>As our global temperatures rise we have seen an unprecedented increase in the extremity of wildfires around the world. In 2021 I travelled to Greece, Canada and the USA to document the aftermath of fires that had destroyed homes, killed numerous people and burnt millions of acres of land. I choose not to document the burning flames, but rather to seek out their aftermath. These portraits are made across different communities and cultures around the world. My subjects pause and engage the camera, looking out from their devastated lives. While their poses may seem conventional, the context is catastrophe, and their gazes are unsettling. They are not disempowered victims: in their encounter with the camera they invite us to engage with the calamity that has befallen them, bearing witness to the impacts of climate change which will be so much worse unless meaningful action is taken.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"ENVGD082",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}ENVGD080.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVGD081.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ENVGD082.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVGD083.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ENVGD084.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVGD085.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}ENVGD086.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}ENVGD087.jpg/info.json`],
            headerText:"<h5>Giacomo d'Orlando </h5>",
            "BodyTextTitle":"Nemo's Garden",
            bodyText:"<i>Nemo's Garden</i><br><br>According to the Intergovernmental Panel on Climate Change (IPCC), the desertification brought by climate change in recent years has already extensively reduced agricultural productivity in many regions of the world. Agriculture represents 70% of freshwater use around the globe and with the world’s population projected to increase to 10 billion by the end of the century, it has become imperative to find alternative and ecologically sustainable methods of cultivation. Nemo’s Garden – the world’s first underwater greenhouse – offers a possible solution. This completely self-sustainable project explores an alternative farming system that could be implemented in areas where environmental or geo-morphological conditions make the growth of plants almost impossible. The encouraging results of the last few years, where more than 40 different species of plants have been successfully cultivated, gives hope that a sustainable agricultural system has been developed to help tackle the new challenges brought by climate change.",
            displayMesh:"Y",
            useMeshButton:"N"
        },
        {
            paintingId:"LANLP092",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}LANLP088.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LANLP089.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LANLP090.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LANLP091.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LANLP092.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LANLP093.jpg/info.json`],
            headerText:"<h5>Lorenzo Poli</h5>",
            "BodyTextTitle":"Life On Earth",
            bodyText:`<i>Life On Earth</i><br><br>Science and religions may all fall short in explaining the incredible miracle of life which, through millennials of evolution, has transformed barren land into a living planet.<br><br>Mother Earth has been regarded by humans through the centuries as a fertility goddess; water is the most incredible terrestrial element, with all living beings depending on it to thrive. Water is the common denominator of the living world.<br><br>There is an untamed world between sacred and magic, where the essence of life is safeguarded by silence, where the outer and the inner world coincide. This is what I am seeking to photograph.<br><br>As philosopher Alan Watts said: "Each one of us, not only human beings but every leaf, every weed, exists in the way it does, only because everything else around it does." And "If you go off into a far, far forest and get very quiet, you'll come to understand that you're connected with everything."`,
            displayMesh:"Y",
            useMeshButton:"N"
        },
        {
            paintingId:"LANAR098",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}LANAR094.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LANAR095.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LANAR096.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LANAR097.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LANAR098.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LANAR099.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LANAR100.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LANAR101.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LANAR102.jpg/info.json`],
            headerText:"<h5>Andrius Repšys</h5>",
            "BodyTextTitle":"Solar Graphic",
            bodyText:"<i>Solar Graphic</i><br><br>In 2021, Lithuania once again experienced a winter of heavy snowfalls - a result of the climate crisis and global warming. Depicted in these photographs are sustainable energy sources such as dams, wind turbines and solar batteries - the very things we need in order to slow down the occurrences of climate disasters. Two of the three main elements of Solar Graphics – seasonality and sustainable energy – organically and purposefully complement each other. Winter’s monochromatic palette helps reveal the graphic elements found in the white snow. As the land is disrupted by dark lines new images appear – a mouth full of dazzling white teeth or a robot with a surprised look on its face. The high vantage point reduces the landscape to abstraction allowing the viewer to find new meanings and interpretations in the photograph.",
            displayMesh:"Y",
            useMeshButton:"N"
        },
        {
            paintingId:"LANGJ105",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}LANGJ103.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LANGJ104.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LANGJ105.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LANGJ106.jpg/info.json`],
            headerText:"<h5>Gareth Iwan Jones</h5>",
            "BodyTextTitle":"Tree",
            bodyText:"<i>Tree</i><br><br>This project was born of the Covid-19 lockdowns, and the impact upon my work as a portrait photographer. Inspired by my home county of Wiltshire, where the distinctive landscape features many knolls with lone trees raised above the horizon line. Unable to photograph people, I turned to my love of trees. I wondered if it was possible to take a unique portrait of these quiet giants. I chose to photograph against dusk skies and lit the trees with drones to create an otherworldly impression.<br><br>As lockdowns took hold, so did this project. I started looking into every field and up every hill  for aesthetically interesting treescapes. While many people discovered the joys of walking in nature during the lockdowns, once the sun had set it was just me, the trees and the darkness, which was an experience that at first terrified me but with time I began to relish.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"POFHF111",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}POFHF107.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}POFHF108.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}POFHF109.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}POFHF110.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}POFHF111.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}POFHF112.jpg/info.json`],
            headerText:"<h5>Hugh Fox</h5>",
            "BodyTextTitle":"Portfolio",
            bodyText:"<i>Portfolio</i><br><br>A selection of images taken over the last couple of years. For me, this series evokes the quiet, isolated, reflective moments I felt during the pandemic.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"POFJA117",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}POFJA113.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}POFJA114.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}POFJA115.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}POFJA116.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}POFJA117.jpg/info.json`],
            headerText:"<h5>Julian Anderson</h5>",
            "BodyTextTitle":"Portfolio",
            bodyText:"<i>Portfolio</i><br><br>A series of portraiture, landscape and feature work shot over the last year or so, some commissioned and some personal.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {
            paintingId:"POFAN123",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}POFAN118.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}POFAN119.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}POFAN120.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}POFAN121.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}POFAN122.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}POFAN123.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}POFAN124.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}POFAN125.jpg/info.json`],
            headerText:"<h5>Anna Neubauer</h5>",
            "BodyTextTitle":"The Beauty of Humanity",
            bodyText:"<i>The Beauty of Humanity</i><br><br>A lot of my work is centred on people with visible differences because I think the less diversity people see in their everyday lives, the more disconcerting they might find it – and that's something I can change. For me, diversity does not exist without inclusion. I’ve realised that as a photographer, I’ve got the transformative power to show how beautiful differences are, and to contribute to a more open, diverse, and inclusive society. Society decides what normal looks like, or what people can and can’t do. With my work, I am moving away from traditional stereotypes by creating images that promote acceptance, kindness and honesty. I am showing what being human actually looks like. With my portraits, I'd like to inspire others to join me, because sometimes all it takes is an inspiring story told through a powerful image.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"PORAF134",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}PORAF126.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORAF127.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORAF128.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORAF129.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORAF130.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORAF131.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORAF132.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORAF133.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORAF134.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORAF135.jpg/info.json`],
            headerText:"<h5>Adam Ferguson</h5>",
            "BodyTextTitle":"Migrantes",
            bodyText:"<i>Migrantes</i><br><br>This is a series of self-portraits of migrants in Mexico, as they wait to cross the border into the United States.The life of a migrant at the border, waiting for the right moment to cross into the United States, is often in flux. To capture a piece of this uncertain journey, I mounted a medium format camera on a tripod with a cable release and then stepped back, allowing the migrants to choose the moment of capture and give them agency in the process of documenting their lives.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"PORGT143",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}PORGT136.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORGT137.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORGT138.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORGT139.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORGT140.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORGT141.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORGT142.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORGT143.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORGT144.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORGT145.jpg/info.json`],
            headerText:"<h5>George Tatakis</h5>",
            "BodyTextTitle":"Caryatis 2021",
            bodyText:"<i>Caryatis 2021</i><br><br>Caryatis is a study of Greek women’s traditional costumes deriving from different time periods in Greece’s rich history. This project evolved out of Tatakis’ previous work, Ethos, which looked at Greek traditions and customs. Each photograph is meticulously staged; postures, attitudes and even hand placements depicted are all typical of the area from which the costume originates.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"PORBS148",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}PORBS146.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORBS147.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}PORBS148.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}PORBS149.jpg/info.json`],
            headerText:"<h5>Brent Stirton</h5>",
            "BodyTextTitle":"Bushmeat Hunters",
            bodyText:"<i>Bushmeat Hunters</i><br><br>This is a series of bushmeat hunter portraits taken in Guyana, Gabon, the Democratic Republic of Congo and the Republic of Congo. These men are seen in an age-old act, bringing animals they have hunted back to their villages. Some of these men are hunting for other, wealthier men who have employed them, others are hunting for their families. In all cases, very little of what they hunted was consumed in the village. Bushmeat commands a high price, which increases as it gets to major cities. These days, hunting of this kind is almost always about an economy of supply and demand.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {
            paintingId:"SPORT152",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}SPORT150.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPORT151.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}SPORT152.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPORT153.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}SPORT154.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}SPORT155.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPORT156.jpg/info.json`],
            headerText:"<h5>Ricardo Teles</h5>",
            "BodyTextTitle":"Kuarup",
            bodyText:"<i>Kuarup</i><br><br>The Kuarup is a ritual of the Xingu Indigenous Brazilian to honour the illustrious dead – it is the farewell and closing of a mourning period. The celebration takes place once a year in different villages and lasts for three days. The highlight of this celebration is a competition of a martial art called Huka-huka, similar to the Greco-Roman wrestling fight, which has competitive symbolism that shows the strength and virility of the young men. These photographs were taken during a Kuarup celebration in the Afukuri village of the Kuikuro ethnic group. This year’s ritual honoured the people who lost their lives between the years 2020 and 2021: four of five were victims of Covid-19.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"SPOAP160",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}SPOAP157.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPOAP158.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}SPOAP159.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPOAP160.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}SPOAP161.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPOAP162.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}SPOAP163.jpg/info.json`],
            headerText:"<h5>Adam Pretty</h5>",
            "BodyTextTitle":"Tokyo Twenty Twenty One",
            bodyText:"<i>Tokyo Twenty Twenty One</i><br><br>The Tokyo 2020 Olympics and Paralympics were postponed for one year and held under really unusual circumstances. Athletes and media were kept inside a bubble and tested every day, with no fans able to witness the greatest sporting show on earth. This series takes a look at the Olympic and Paralympic sports that I was assigned to cover in Tokyo. I tried to find unique and challenging angles to minimise the visual impact of not having a crowd or any fans (and atmosphere) and also to illustrate the beauty and strength of the athletes and sports as well as their emotions from slightly different angles. It was perhaps the most challenging Olympics I have ever been part of, and I can only imagine what a roller coaster of emotions the athletes were experiencing. I was very grateful when these emotions spilled over in the heat of competition.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"SPORV164",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}SPORV164.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPORV165.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}SPORV166.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPORV167.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}SPORV168.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPORV169.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}SPORV170.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}SPORV171.jpg/info.json`],
            headerText:"<h5>Roman Vondrouš</h5>",
            "BodyTextTitle":"Loyal Fans",
            bodyText:"<i>Loyal Fans</i><br><br>The fans of the football club Bohemians Prague 1905 are among the most loyal in the Czech Republic. They’ve helped the club financially through collections at times when it was struggling to survive. Their loyalty, zeal and devotion to the club did not waver even when spectators were banned from watching the matches of the top football league, due to the Covid pandemic. Not wanting to miss a home match under any circumstances, they brought ladders and watched the matches over the wall of the football stadium. The stadium of Bohemians Prague 1905, nicknamed Ďolíček, is outdated and will soon undergo a major reconstruction. But it has its own unmistakable genius loci in the Prague district of Vršovice.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"STIHO176",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STIHO172.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STIHO173.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STIHO174.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STIHO175.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STIHO176.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STIHO177.jpg/info.json`],
            headerText:"<h5>Haruna Ogata and Jean-Etienne Portail</h5>",
            bodyText:"<i>Constellation</i><br><br>These images were taken in a studio in Paris, in September 2020 – for the pure creation of a still life photo.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"STICN184",
            videoId:"",
            videoURL : "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STICN178.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STICN179.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STICN180.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STICN181.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STICN182.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STICN183.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STICN184.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STICN185.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STICN186.jpg/info.json`],
            headerText:"<h5>Cletus Nelson Nwadike</h5>",
            bodyText:"<i>From Nigeria to Nässjö</i><br><br>My mother died on 26 September 2021. She never got to see four of her grandchildren – she was refused a visa to come and visit them. The reason was that I do not make enough money. I am a photographer. This work is to honour my mother.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

    {
        paintingId:"STIAG195",
        videoId:"",
        videoURL: "",
        iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STIAG187.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STIAG188.jpg/info.json`,
            `${CANTALOUPE_IMAGE_SERVER}STIAG189.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STIAG190.jpg/info.json`,
            `${CANTALOUPE_IMAGE_SERVER}STIAG191.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STIAG192.jpg/info.json`,
            `${CANTALOUPE_IMAGE_SERVER}STIAG193.jpg/info.json`,`${CANTALOUPE_IMAGE_SERVER}STIAG194.jpg/info.json`,
            `${CANTALOUPE_IMAGE_SERVER}STIAG195.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STIAG196.jpg/info.json`],
        headerText:"<h5>Alessandro Gandolfi</h5>",
        bodyText:"<i>Concordia</i><br><br>On the evening of 13 January 2012, the Costa Concordia, a cruise ship with 4229 people on board, struck shallow water near the island of Giglio. Within three hours it had sunk sideways to the bottom, a few metres from the coast. This was the largest ever cruise ship to sink, and the disaster claimed 32 lives. But what is left of that event, 10 years on?<br><br>Today the Costa Concordia no longer exists as its name has been cancelled from the Italian shipping register. The wreck, which is a symbol of the drama, was hauled up and dismantled, and most of the parts were recycled: thousands of tonnes of steel from the ship now form the foundations of Italian bridges and buildings. But traces of the Concordia can be found everywhere. These are relics that tell stories.",
        displayMesh:"Y",
        useMeshButton:"N"
    },
        {
            paintingId:"WILMR200",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}WILMR197.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}WILMR198.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}WILMR199.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}WILMR200.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}WILMR201.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}WILMR202.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}WILMR203.jpg/info.json`],
            headerText:"<h5>Milan Radisics</h5>",
            bodyText:"<i>The Fox’s Tale</i><br><br>Over eight months, I spent almost every night sitting at the window of my cottage in the middle of the forest – where wild animals live almost as neighbours of the villagers. The young vixen appears in the village after dusk, circles an hour and a half, and appears in a courtyard several times. I observed her movements and behaviour from the darkened room, and took the exposure remotely. I named her Roxy. I set the lights in advance, like in a studio, and waited for the protagonist to walk into the scenes. She always surprises me, showing a new side, and I have had to solve many technical, theoretical, and physical challenges in the process of photographing her. During lockdown, both sides were forced to adjust: man to the wilds of the forest, animals to the human environment.",
            displayMesh:"Y",
            useMeshButton:"N"
        },
        {
            paintingId:"WILFB208",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}WILFB204.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}WILFB205.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}WILFB206.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}WILFB207.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}WILFB208.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}WILFB209.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}WILFB210.jpg/info.json`],
            headerText:"<h5>Federico Borella</h5>",
            bodyText:"<i>Exotic Appetite: Inside the Italian Exotic Animal Trade</i><br><br>It's common to think that ’wet markets’ only exist in Asian countries, but in Italy there are also fairs at which thousands of exotic animals are displayed and sold, and huge profits are made. At these fairs, exotic animals are considered collectibles. They’re often stressed, kept in plastic trays the same size as the animals they contain, and anyone can touch them. Among the stands it's not uncommon to find animals who are solitary by nature, forced to live in groups, and animals with a strong social nature isolated in plastic boxes. There is also the question of potential risks to human health.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"WILOB213",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}WILOB211.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}WILOB212.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}WILOB213.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}WILOB214.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}WILOB215.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}WILOB216.jpg/info.json`],
            headerText:"<h5>Oana Bakovic</h5>",
            bodyText:`<i>Absolute Beginner</i><br><br>My art emerges from the need to reconnect with the beautiful nature surrounding us. As a human, I enjoy the wonders of discovery and constant advancement that define us as species, but I am also sad that we overlook the wonders that are so close to us. This feeling set me on a path of exploration that I continue to follow.<br><br>These photographs document the tragic beauty of disruptions to nature happening before our eyes. The shots, taken in my local area, are meant to draw attention to the esoteric signs that nature gives us at every step. The images were shot using a combination of ambient light and flash, sometimes ND filters. I colour-graded them in Capture One and Lightroom.`,
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"LATAA224",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}LATAA217.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LATAA218.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LATAA219.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LATAA220.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LATAA221.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LATAA222.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}LATAA223.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}LATAA224.jpg/info.json`],
            headerText:"<h5>Alejandra Aragon</h5>",
            bodyText:"<i>I Came To La Pinta Because They Told Me My Father Lived Here</i><br><br>The title of this project was inspired by the opening sentence in the emblematic Mexican novel Pedro Páramo. Like the protagonist in that story, I too began a journey between Mexico and the United States to find my biological father. In the process, I discovered how the wounds of my family are linked to the demands of hegemonic masculinity and the displacements of the agricultural towns from northern Mexico. I returned to the hometowns my family came from to find them being disputed by organised crime and the government’s neoliberal agenda. Both state and father figure broke their promises, and there is no motherland to return to from exile. To define the visual language of this story I used images from the family photo album alongside ones I've taken during my travels with analogue film cameras. I avoided using a DSLR in order to both question the codes under which violence in Mexico is commonly portrayed and protect the intimacy of the story. The colours of the infrared film (an outdated military technology) reveals an invisible spectrum of light, creating a dystopian atmosphere. It’s complemented by the voices of family members and fragments of corridos, a storytelling genre in popular music.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"ARCAC225",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}ARCAC225.jpg/info.json`,
            headerText:"<h5>Anthony Chan</h5>",
            bodyText:"<i>Old Meets New</i><br><br>In an industrial district in Hong Kong, surrounded by a mix of old and new developments, this shot was taken to highlight a contrasting co-existence: a new skyscraper’s stylishly designed front entrance and an old industrial complex with myriads of air conditioners, pipes and wires mounted on its wall. The shadow and light accentuated the overall monochromic effect of the city’s architecture",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {paintingId:"CREIS226",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}CREIS226.jpg/info.json`,
            headerText:"<h5>Isabel Salmon</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>I began to document my mum’s experience with labyrinthitis and PPPD – a sensory documentation of her condition. I am finding ways to explore this through experimental techniques, with the intention of combining sound, and also considering other senses. I hope to create an installation in collaboration with my mum, using a series of conversations with her.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {
            paintingId:"LANVA227",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}LANVA227.jpg/info.json`,
            headerText:"<h5>Vicente Ansola</h5>",
            bodyText:"<i>Exodus</i><br><br>I encountered this field of sunflowers in Castilla and León. Sunflowers always seek the divine by following the sun on its route. These ones, although withered, stood up proudly. When I grabbed my camera to shoot, I no longer saw sunflowers but women walking the dry fields of Castilla, wearing ancestral garments. An army of spirits – the inexorable exodus of the Spanish rural.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {paintingId:"ENVUA228",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}ENVUA228.jpg/info.json`,
            headerText:"<h5>Utsab Ahamed Akash</h5>",
            bodyText:"<i>The Honey Collector</i><br><br>The honey collectors collect in winter. They place the bees’ nests near the mustard field as they can produce more honey that way. We often see this kind of scene in the Bangladesh countryside.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SPORN229",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SPORN229.jpg/info.json`,
            headerText:"<h5>Raido Nurk</h5>",
            bodyText:"<i>Surfing festival</i><br><br>The waves were the biggest I’ve ever seen in the evening when I took this photo in The Hague, Netherlands. The waves and the pouring rain created quite a unique atmosphere.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"WILSW230",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}WILSW230.jpg/info.json`,
            headerText:"<h5>Scott Wilson</h5>",
            bodyText:"<i>Anger Management</i><br><br>A wild mustang stallion kicks up a dust storm in northwestern Colorado.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"STILR231",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}STILR231.jpg/info.json`,
            headerText:"<h5>Leonardo Reyes-González</h5>",
            bodyText:"<i>Segundo Uso (Second Use) II</i><br><br>Still life photography of things thrown away (aka trash), that might be useful for someone else - or could, hopefully, become art.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"PORSC232",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}PORSC232.jpg/info.json`,
            headerText:"<h5>Simone Corallini</h5>",
            bodyText:"<i>The Endless Summer - Surf Trip</i><br><br>Simone Gentile, a 16-year-old surfer.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"DOCES233",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}DOCES233.jpg/info.json`,
            headerText:"<h5>Simone Corallini</h5>",
            
            "BodyTextTitle":"The Endless Summer - Surf Trip",
            bodyText:"<i>The Endless Summer - Surf Trip</i><br><br>Simone Gentile, a 16-year-old surfer.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFTP234",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFTP234.jpg/info.json`,
            headerText:"<h5>Thanh Nguyen Phuc</h5>",
            
            "BodyTextTitle":"Bike with Flowers",
            bodyText:"<i>Bike with Flowers</i><br><br>A hundred years ago there were just 36 streets and now there are many more, but the street culture remains strong in Hanoi. There are lots of shops in the main streets but people in the old streets prefer to get serviced by mobile street vendors. I spent a weekend following street vendors and found that they were walking or riding their bikes all day. Here is one of my favourite moments.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFUS235",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFUS235.jpg/info.json`,
            headerText:"<h5>Ute-Christa Scherhag</h5>",
            
            "BodyTextTitle":"Small Highway Church",
            bodyText:"<i>Small Highway Church</i><br><br>Taken in front of a small highway church at a service area in Siegerland, in the middle of Germany,  this photo is part of a small series.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFHL236",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFHL236.jpg/info.json`,
            headerText:"<h5>Hanna Lisava</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>A creative portrait of a friend, using colours.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFIE237",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFIE237.jpg/info.json`,
            headerText:"<h5>Isabela Eseverri</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>Macaws during their early morning flight in Caracas, Venezuela, on 27 November, 2021. Not autochthonous to the area, these birds have become an invasive species in the last few years – they are a threat to the local bird population, and some even dare to say, a plague.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFYY238",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFYY238.jpg/info.json`,
            headerText:"<h5>Yiming Yang</h5>",
            
            "BodyTextTitle":"The Horse, a Whirlwind",
            bodyText:"<i>The Horse, a Whirlwind</i><br><br>An aerial view of horses running in Fenghuang horse ranch, in Inner Mongolia, on 26 September, 2021.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFKN239",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFKN239.jpg/info.json`,
            headerText:"<h5>Kantaya New</h5>",
            
            "BodyTextTitle":"Eye Contact",
            bodyText:"<i>Eye Contact</i><br><br>A man is staring back at a life-sized photo of a T-Rex. It looks likethe man and the dinosaur are playing a staring game. A replay of David versus Goliath – but who will have the final laugh? This dangerously impossible mission catches the attention of a mother and her daughter.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFEJ240",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFEJ240.jpg/info.json`,
            headerText:"<h5>Ewa Jermakowicz</h5>",
            
            "BodyTextTitle":"The Journey",
            bodyText:"<i>The Journey</i><br><br>Elephants usually travel in large herds, protecting their youngest by keeping them hidden in the middle of the group. But kids are just kids, regardless of the species, and try to test their limits. This little elephant managed to escape from its mother for a short couple of seconds, proudly leading the family and making a lot of dust with its tiny legs.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFDO241",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFDO241.jpg/info.json`,
            headerText:"<h5>Dinorah Graue Obscura</h5>",
            
            "BodyTextTitle":"Crowned Tree Frog",
            bodyText:"<i>Crowned Tree Frog</i><br><br>On a night walk that I did in September, in Guapiles, Costa Rica, we came across this jewel of the rainforest:  a crowned tree frog. It seemed as if it was made of chocolate – and if you look closer, it has a beautiful crown. The symmetry on its head is just perfect.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFMS242",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFMS242.jpg/info.json`,
            headerText:"<h5>Maryia Sapego</h5>",
            
            "BodyTextTitle":"There Is a Shadow",
            bodyText:"<i>There Is a Shadow</i><br><br>Everything has a shadow, even a small flower.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFNT243",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFNT243.jpg/info.json`,
            headerText:"<h5>Nina TBerg</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>My girl is entertaining herself while her brother is training in the next field.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFKC244",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFKC244.jpg/info.json`,
            headerText:"<h5>Klaudia Chwastek</h5>",
            
            "BodyTextTitle":"Main Railway Station in Częstochowa",
            bodyText:"<i>Main Railway Station in Częstochowa</i><br><br>The sun sets on the main railway station in Częstochowa, Poland.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFCA245",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFCA245.jpg/info.json`,
            headerText:"<h5>Cigdem Ayyildiz</h5>",
            
            "BodyTextTitle":"Le Mont Saint-Michel at Sunset",
            bodyText:"<i>Le Mont Saint-Michel at Sunset</i><br><br>For me, this piece of art on the shores of Normandy is a candidate for Eighth Wonder of the World; providing a legendary view and atmosphere especially at sunset and when the tide is low.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"SAFRC246",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}SAFRC246.jpg/info.json`,
            headerText:"<h5>Raquel Inés Correa Chiesa</h5>",
            
            "BodyTextTitle":"Eye Contact",
            bodyText:"<i>Eye Contact</i><br><br>I spent several days going up to the mountains to look for this insect, libelloides hispanicus, and one day with a lot of wind, I got it. Our eyes stayed connected.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {
            paintingId:"STUAM250",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STUAM247.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUAM248.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STUAM249.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUAM250.jpg/info.json`],
            headerText:"<h5>Aidan Murgatroyd</h5>",
            bodyText:"<i>Becoming South African</i><br><br>As a third culture kid born and raised in various regions of the world and spending more time outside South Africa than in it, I've always desired to explore, understand and connect with what it means to be South African. As a child of mixed cultural background with one English and one Afrikaans parent, even in the midst of family gatherings, I felt a sense of otherness. As a result upon my return to South Africa in 2017, and the rapid onset of covid thereafter, I had not had the necessary freedom in order to travel, connect and interact with the people, places and spaces that make up the South African experience. Thus at the first chance available, I undertook a countrywide road trip over 5 months. The goal? To ascertain what it means to be South African, especially in a nation which encompasses so many cultures/languages/identities. Who are we?",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"STUAK253",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STUAK251.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUAK252.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STUAK253.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUAK254.jpg/info.json`],
            headerText:"<h5>Alexander Komenda</h5>",
            bodyText:"<i>Tiramisu</i><br><br>The term Tiramisu can be understood as a pick-me up or cheer me up. Visual stanzas gathered into a multi-narrative piece that’s a testament to connecting with those closest to our living space, and what can be accomplished as a team between wit, spontaneity and action. Friends, roommates and neighbours. What’s closest to home, the opposite of the photographer’s obsession to exoticize - fellow foreigners that convene in the familiar feeling of being somewhere new. Covid-19 and the stark winter darkness of the 60th degree. Exploring via dialectic, with emphasis on the collaborative process where the subjects provide ideas on where and how to be photographed; casting aside the dictatorial picture-maker; a wolf leading from behind. Members of the Chinese student community in Espoo, Finland, aiming at subverting stereotypical categorizations of what’s typically viewed in the media and a chance at writing their own tale. The undertaking itself as a healthy social support system that culminates into a document that’s situated between fact and fiction - sporadically implementing a collective imagination that shapes experience, interconnectivity and memory. A familiar life ritual at a crossroads of larger global phenomena. An opportunity to knock at the neighbour’s room and showcase the thrill of togetherness; a simplicity slowly dissipating via virtual hyperconnectivity. A chance to reimagine quotidian surroundings, the stairs used weekly to do the laundry, the bed that’s slept on, the expressions that are manifested after a profound statement or an embarrassing instant. Sitting at the common room table, sharing home-cooked meals, continuing the evenings’ gathering by placing the mirror uncannily in an attempt to reveal something new.",
            displayMesh:"",
            useMeshButton:""
        },

        {
            paintingId:"STUAS255",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STUAS255.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUAS256.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STUAS257.jpg/info.json`],
            headerText:"<h5>Angela Stoll</h5>",
            bodyText:"<i>Even When We're Breathing, We're Moving</i><br><br>For me, finding a way of connecting to the world and feeling grounded during this difficult time is through dance. Dancing is a way of connecting with my environment, by being present within the space and allowing the movement to direct me around my surroundings. By dancing in an abandoned building enables me to not only freely express how I feel, but to develop a connection with an empty environment that has now been forgotten. Therefore, movement is a passage of time that provides a visual form of how one got from beginning to end. Exploring the relationship between time and movement through dance, reveals a narrative showcasing how the body remembers what the mind has forgotten within this emerging world.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"STUCV261",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STUCV258.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUCV259.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STUCV260.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUCV261.jpg/info.json`],
            headerText:"<h5>Chris Rosas Vargas</h5>",
            bodyText:"<i>Cariño</i><br><br>For the past couple of months I have developed a series of sentimental portraits of my communities in both the Bronx and Harlem. This project explores the sentimental and tender relationship between myself and the urban landscape. Photographs included consist of environmental portraits of found objects. As opposed to following the traditional photojournalistic style, I explore a more nuanced and nostalgic approach to making photographs that deeply resonate with notions of home. Through the use of color and composition I construct a warm and familiar environment which at times did not exist and would cast me out as a queer person of color.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"STUDK262",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STUDK262.jpg/info.json`,`${CANTALOUPE_IMAGE_SERVER}STUDK263.jpg/info.json`],
            headerText:"<h5>Dennis Mubanga Kabwe</h5>",
            bodyText:"<i>The Butcher</i><br><br>The photograph was taken on 24th June, 2021 at Chilenje Market in Lusaka, Zambia. The photograph goes into the life of Mr. Banda a butcher who is passionate about what he does. \nI shot the image with the Canon 5D Mark IV with a 24-105mm Lens with two point lighting.\nWe run a photography company called UMUBANGA MEDIA LIMITED",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"STUEB269",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STUEB264.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUEB265.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STUEB266.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUEB267.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STUEB268.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUEB269.jpg/info.json`],
            headerText:"<h5>Ezra Bohm</h5>",
            bodyText:"<i>The Identity of Holland</i><br><br>In this series I photograph the last people wearing the traditional costumes of the Holland. This work is intended to celebrate and cherish the old culture of the Netherlands. These groups have something in common that we often miss in modern society: solidarity, geniality and collective pride.\nmodern citizens ofter are disconnected to their own roots, which I think is very important to form your identity with.\nI romanticize a world that I want to be a part of. The people in the picture are my superheroes, they have to inspire people to look back at our origins and learn from the past",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"STUOM271",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STUOM270.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUOM271.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STUOM272.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUOM273.jpg/info.json`],
            headerText:"<h5>Oriel Naxhielly Martins</h5>",
            bodyText:`<i>Disconnected</i><br><br>As someone who frequently ran away from their parent´s house, it's easy for me to find a home through intimacy with others.<br><br>The foundations of my identity were built from the relationship with my peers.<br><br>The isolation caused by the pandemic made me go through really hard times. Feeling so disconnected blurred the reflection of my self-perception, and being incapable of meeting people increased my anxiety and unnecessary self-awareness.<br><br>But being able to portray myself and my environment, made the introspective process less painful. Photography allowed me to create a personal document which, voiced in tangible images, eased the burden.<br><br>Just like a picture, I'm not completed until other eyes see me.<br><br>As the restrictions adopted to prevent the spread of the COVID-19 decrease, I’m finding chances to reunite with my loved ones to hopefully start feeling whole again.`,
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"STUSD275",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STUSD274.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STUSD275.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUSD276.jpg/info.json`],
            headerText:"<h5>Sachi Deshmukh</h5>",
            bodyText:"<i>Connections</i><br><br>Through memories we can not only trace our connections to the past, but also discover connecting links between past and present. Humans celebrate their attachments through remembrance.  \n",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"STUXH280",
            videoId:"",
            videoURL: "",
            iiifMultiSource: [`${CANTALOUPE_IMAGE_SERVER}STUXH277.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUXH278.jpg/info.json`,
                `${CANTALOUPE_IMAGE_SERVER}STUXH279.jpg/info.json`, `${CANTALOUPE_IMAGE_SERVER}STUXH280.jpg/info.json`],
            headerText:"<h5>Xu Han</h5>",
            bodyText:"<i>Memories of family</i><br><br>When I entered college, I began to think about my relationship with my family. This group of works metaphors my feelings for my father and mother, which are intimate but separated. Finally, I chose to use paint to express my melancholy and hesitation. This group of works was shot in June 2021 and completed in Nanjing Academy of art.",
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {
            paintingId:"NATGS281",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATGS281.jpg/info.json`,
            headerText:"<h5>Gonzalo Javier Santile</h5>",
            
            "BodyTextTitle":"El Vigia",
            bodyText:"<i>El Vigia</i><br><br>A strong, hot wind known as Los Colorados was blowing when I took this photograph in Cafayete, Argentina, so I buried my tripod the best I could, using rocks to stabilise it. The image consists of 20 shots – six of the sky and six of the foreground, shot at 15mm with a shutter speed of 20 seconds. I love the combination of the Milky Way and these unique mountain rocks.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATSI282",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATSI282.jpg/info.json`,
            headerText:"<h5>Sonja Ivancsics</h5>",
            
            "BodyTextTitle":"Milky Way in Salzkammergut",
            bodyText:"<i>Milky Way in Salzkammergut</i><br><br>I planned this photo for about a year, and on that night in June 2021 the conditions were just perfect. The Gosausee lake in Salzkammergut reflects its surrounding mountains beautifully, especially the mountain Dachstein with its glacier. The picture blends two images, one taken in the blue hour, and one taken at about midnight.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATKA283",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATKA283.jpg/info.json`,
            headerText:"<h5>Kazi Arifuzzaman</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>Working together.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATPM284",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATPM284.jpg/info.json`,
            headerText:"<h5>Paola Lambertin Murillo</h5>",
            
            "BodyTextTitle":"Ephemeral Portrait",
            bodyText:"<i>Ephemeral Portrait</i><br><br>This photo was taken in Bolivia in March, using a smoke flare. The concept is that our identity is constantly changing; a photographic portrait captures only a minute fraction of our identity, which is why I photographed smoke as an analogy.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMV285",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMV285.jpg/info.json`,
            headerText:"<h5>Mathis Vandermeeren</h5>",
            
            "BodyTextTitle":"Earth's Eye",
            bodyText:"<i>Earth's Eye</i><br><br>This picture was taken with my drone in a geothermal area called Hverravellir in the centre of Iceland, in August 2021. It shows a natural hot spring that has a wonderful blue colour, especially when seen from above.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMM286",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMM286.jpg/info.json`,
            headerText:"<h5>Minko Mihaylov</h5>",
            
            "BodyTextTitle":"Foggy morning",
            bodyText:"<i>Foggy morning</i><br><br>A foggy morning by the Rowing Canal in the city of Plovdiv, Bulgaria.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATSC287",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATSC287.jpg/info.json`,
            headerText:"<h5>Sean Channal</h5>",
            
            "BodyTextTitle":"Shadows of Buddhism",
            bodyText:"<i>Shadows of Buddhism</i><br><br>After studying in a quiet place near the Buddha statue, the monk often returns to the hut with an umbrella for shade, here representing both a sunshade and the shade of Buddhist serenity.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATOS288",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATOS288.jpg/info.json`,
            headerText:"<h5>Oscar Seguel</h5>",
            
            "BodyTextTitle":"Ballerina & B-Boy",
            bodyText:"<i>Ballerina & B-Boy</i><br><br>This photo was taken in August 2021 in the Republica neighborhood of Santiago de Chile.\nAriela is a ballet dancer and John is an urban dancer.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATBS289",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATBS289.jpg/info.json`,
            headerText:"<h5>Bernardo del Cristo Hernandez Sierra</h5>",
            
            "BodyTextTitle":"Through the Roof",
            bodyText:"<i>Through the Roof</i><br><br>A diver jumps from a 10 metre platform during a national diving competition in Medellin, Colombia. The photo was taken in August 2021.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATJR290",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATJR290.jpg/info.json`,
            headerText:"<h5>Juan Carlos Rodriguez</h5>",
            
            "BodyTextTitle":"A Woman in a Post-modern World",
            bodyText:"<i>A Woman in a Post-modern World</i><br><br>This portrait was captured in the market of Leh. The woman depicted belongs to the ethnic group of Dard Aryans living in the valleys of Ladakh. These people see themselves as descendants of Alexander the Great. Their eclectic look, mixing traditional attire with touches of modernity, immediately appealed to me. The dark background of the market allowed me to isolate the subject from any distracting surrounding elements.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMP291",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMP291.jpg/info.json`,
            headerText:"<h5>Majda Pavlekovic</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>In August 2021, Croatian Paralympic swimmer Tomi Brajsa was preparing for the Tokyo 2020 Paralympic games by the open pool in Salata, Zagreb. This photo was taken early in the morning of a rainy day on 18 August. Tomi is a Croatian para-swimming record holder in the S4 and SM4 categories. In Tokyo he was competing in two races: 50m freestyle S4 and 200m freestyle S4, where he won 12th place.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMD292",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMD292.jpg/info.json`,
            headerText:"<h5>Martina Dimunova</h5>",
            
            "BodyTextTitle":"Silence",
            bodyText:"<i>Silence</i><br><br>With this photograph I've attempted to capture the feeling of inner peace",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATSA293",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATSA293.jpg/info.json`,
            headerText:"<h5>Sussi Charlotte Alminde</h5>",
            
            "BodyTextTitle":"Silence is the Most Powerful Scream",
            bodyText:"<i>Silence is the Most Powerful Scream</i><br><br>A forest story created on a cold winter’s day on 18 February, 2021. The idea was purely and simply to create a personal piece of art.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATJA294",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATJA294.jpg/info.json`,
            headerText:"<h5>Joel Alonso Rodríguez</h5>",
            
            "BodyTextTitle":"Human Element",
            bodyText:"<i>Human Element</i><br><br>This image was taken in the theatre of the Polytechnic University of Ecuador, designed and built by Oswaldo de la Torre. Through my lens I wanted to reveal the relationship between a person and an architectural space – how the human being becomes another element in the spatial composition.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATSS295",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATSS295.jpg/info.json`,
            headerText:"<h5>Sherif Salem</h5>",
            
            "BodyTextTitle":"A Quiet Place",
            bodyText:"<i>A Quiet Place</i><br><br>A morning scene from the countryside where antique cars add a classic touch. The photo was taken with a 50mm lens on a photo tour in September, in the village of Idku in northern Egypt.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATRN296",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATRN296.jpg/info.json`,
            headerText:"<h5>Raido Nurk</h5>",
            
            "BodyTextTitle":"Surfing festival",
            bodyText:"<i>Surfing festival</i><br><br>The waves were the biggest I’ve ever seen in the evening when I took this photo in The Hague, Netherlands. The waves and the pouring rain created quite a unique atmosphere.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATDB297",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATDB297.jpg/info.json`,
            headerText:"<h5>Damon Beckford</h5>",
            
            "BodyTextTitle":"Frozen Rock",
            bodyText:"<i>Frozen Rock</i><br><br>In January 2021 the temperature went down to -25°C on the south coast of Finland. I went to Emäsalo in the city of Porvoo to capture the freezing of the Baltic Sea. As the sea starts to freeze, it creates beautiful ice sculptures on the rocks, and a few days later the sea is frozen. The picture was shot at night with a slow shutter speed on extremely slippery ice.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATPB298",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATPB298.jpg/info.json`,
            headerText:"<h5>Patrick Bosc</h5>",
            
            "BodyTextTitle":"Mathis",
            bodyText:"<i>Mathis</i><br><br>The model, Mathis Fugeray, was portrayed using the collodion process with silver on a 18x24cm glass plate. I used a vintage large format camera from 1910 with a 3B Petzval 290mm f/4 lens from Perken, Son and Rayment from circa 1890.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATFL299",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATFL299.jpg/info.json`,
            headerText:"<h5>Frank Loddenkemper</h5>",
            
            "BodyTextTitle":"James Simon Galerie, Berlin",
            bodyText:"<i>James Simon Galerie, Berlin</i><br><br>The James Simon Gallery on  Museum Island in Berlin, photographed in October 2021.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATFZ300",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATFZ300.jpg/info.json`,
            headerText:"<h5>Foteini Zaglara</h5>",
            
            "BodyTextTitle":"Rolling Boil",
            bodyText:"<i>Rolling Boil</i><br><br>Self-portrait.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATJG301",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATJG301.jpg/info.json`,
            headerText:"<h5>Juan Pablo Méndez Garzona</h5>",
            
            "BodyTextTitle":"The Beauty of Northern Lights Above the Arctic Henge",
            bodyText:"<i>The Beauty of Northern Lights Above the Arctic Henge</i><br><br>This picture combines two photos taken during my trip to Iceland. The first one was taken at the Arctic Henge, a group of structures located in Raufarhofn on the northeastern tip of the Melrakkaslétta peninsula. The second one is the sky full of northern lights, which I captured a few days later. For me, this composition represents an open door full of possibilities for those who dare to walk into the unknown.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATEC302",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATEC302.jpg/info.json`,
            headerText:"<h5>Edina Csoboth</h5>",
            
            "BodyTextTitle":"Rea",
            bodyText:"<i>Rea</i><br><br>A classic portrait.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATVV303",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATVV303.jpg/info.json`,
            headerText:"<h5>Viktor Einar Vilhelmsson</h5>",
            
            "BodyTextTitle":"Above",
            bodyText:"<i>Above</i><br><br>Strapped in and standing above a big cliff. Watching the glacier go over the edge and fall 200 meters to the ground.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATHK304",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATHK304.jpg/info.json`,
            headerText:"<h5>Haider Khan</h5>",
            
            "BodyTextTitle":"White Stallion",
            bodyText:"<i>White Stallion</i><br><br>The Marwari or Malani is a rare breed of horse from the Marwar region of Rajasthan in northwest India, with an unusual, inward-curving ear shape.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMM305",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMM305.jpg/info.json`,
            headerText:"<h5>Metha Meiryna</h5>",
            
            "BodyTextTitle":"The Rejang Dancer",
            bodyText:"<i>The Rejang Dancer</i><br><br>In Bali, dancing is part of local traditions and religious ceremonies. One of the most beautiful dances is Rejang. Dressed in great costumes with headdresses, this dance is traditionally performed by young women at a ceremony in the inner courtyard of a temple. This dance is also one of the holiest sacrificial dances, performed to entertain visiting gods and spirits.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATAP306",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATAP306.jpg/info.json`,
            headerText:"<h5>Antonino Pellicano</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>This is a story about Riccardo, the lord of fashion. A tailor par excellence. He represents an era in Gianni Versace's home town of Reggio Calabria, Italy, as a man with a humble attitude and innate class. Within him is the art of creation that has nourished his life for nearly eighty years. The artisan fashion shop continues to live within him.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMO307",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMO307.jpg/info.json`,
            headerText:"<h5>Mituhiro Okabe</h5>",
            
            "BodyTextTitle":"Milky Way & Circle",
            bodyText:"<i>Milky Way & Circle</i><br><br>April on the coast of Japan. I drew the foreground with light paint against the background of the Milky Way.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATKS308",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATKS308.jpg/info.json`,
            headerText:"<h5>Kaoru Sugiyama</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>People watching the full moon on the roof of a building. In Japan, celebrating the beauty of the moon is a popular pastime called ‘moon viewing’ or Tsukimi.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATYT309",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATYT309.jpg/info.json`,
            headerText:"<h5>Yasuhiro Takachi</h5>",
            
            "BodyTextTitle":"Masked Man in Tokyo",
            bodyText:"<i>Masked Man in Tokyo</i><br><br>A scene in front of Tokyo Station during the declaration of a state of emergency related to Covid-19.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATWC310",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATWC310.jpg/info.json`,
            headerText:"<h5>Wonyoung Choi</h5>",
            
            "BodyTextTitle":"A City Among the Clouds",
            bodyText:"<i>A City Among the Clouds</i><br><br>This is a photograph from Bukhansan Mountain in Seoul, South Korea. Lots of people climb the mountain to view the sunrise, but it’s a rare sight to see the city covered in clouds as the sun rises. I've gone there many times and was lucky to finally see it. I feel gratitude towards Mother Nature for changing Seoul's architecture from monotonous to colourful.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATWC311",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATWC311.jpg/info.json`,
            headerText:"<h5>Wookeun Choi</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>In the late afternoon, I photographed an atmosphere created by a woman and her shadow coming through the impressive structure of this building in Jeju, South Korea.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATPW312",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATPW312.jpg/info.json`,
            headerText:"<h5>Jung Woon Park</h5>",
            
            "BodyTextTitle":"Curve and Contrast",
            bodyText:"<i>Curve and Contrast</i><br><br>I wanted to capture the harmony of the curves of the setting and of the model’s clothes. To bring out the curves of both, I used a light on one side to create contrast. Placing the model in her colourful, red, yellow and blue dress against a black and white background also added contrast to the image.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMM313",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMM313.jpg/info.json`,
            headerText:"<h5>Mohammad Mirza</h5>",
            
            "BodyTextTitle":"Giant Tusker",
            bodyText:"<i>Giant Tusker</i><br><br>Photographing a giant tusker like Craig was a big challenge for me. Getting so close, within a few metres from this big bull, and shooting from a very low angle with a wide lens, requires some courage! After five days with several attempts at  photographing Craig in an open space, I managed to get this shot face to face, with mount Kilimanjaro as a backdrop.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATVR314",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATVR314.jpg/info.json`,
            headerText:"<h5>Viktors Rimarevs</h5>",
            
            "BodyTextTitle":"Haze",
            bodyText:"<i>Haze</i><br><br>I shot this in August on a day with low fog by the Baltic sea in Latvia.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATVM315",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATVM315.jpg/info.json`,
            headerText:"<h5>Virginija Mureikienė</h5>",
            
            "BodyTextTitle":"Dust",
            bodyText:"<i>Dust</i><br><br>In the process of making. This photograph was taken in a woodworker’s shop while he was cleaning dust from a small box he just finished polishing. A still with movement.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATYC316",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATYC316.jpg/info.json`,
            headerText:"<h5>Yih Chang Chew</h5>",
            
            "BodyTextTitle":"A Top Down View of Penang’s First Bridge",
            bodyText:"<i>A Top Down View of Penang’s First Bridge</i><br><br>The 13.5-km Penang bridge highway is the second-longest bridge in Malaysia. It was built in 1985 and until 2014 it was the only road connection between Peninsular Malaysia and Penang Island. Here it’s seen from the island end.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATSC317",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATSC317.jpg/info.json`,
            headerText:"<h5>Sergio Carrasco</h5>",
            
            "BodyTextTitle":"The Scent of Cempasúchil",
            bodyText:"<i>The Scent of Cempasúchil</i><br><br>Mexican Catrina, an icon of the Day of the Dead, wearing a typical Mexican dress from the state of Chiapas. She is standing in a field of Mexican marigold, or Cempasúchil, a flower traditionally used for Mexican Day of the Dead celebrations. Every year, my wife puts on a different Catrina costume to celebrate our tradition.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATST318",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATST318.jpg/info.json`,
            headerText:"<h5>Swe Tun</h5>",
            
            "BodyTextTitle":"Twin Sisters",
            bodyText:"<i>Twin Sisters</i><br><br>This is a portrait of two twin sisters at a Buddhist monastery near the Hpa-an township in Myanmar, taken on 2 May, 2021.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATDT319",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATDT319.jpg/info.json`,
            headerText:"<h5>Dikpal Thapa</h5>",
            
            "BodyTextTitle":"The Young Priest",
            bodyText:"<i>The Young Priest</i><br><br>A Young Priest from Thecho, Lalitpur carrying butter lamps on his head and on his palms during the Madhav Narayan festival celebration.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATHC320",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATHC320.jpg/info.json`,
            headerText:"<h5>Harrie Coehorst</h5>",
            
            "BodyTextTitle":"Passing By",
            bodyText:"<i>Passing By</i><br><br>Two passers-by create a perfect composition.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATST321",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATST321.jpg/info.json`,
            headerText:"<h5>Samakinwa Emmanuel Temitope</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>A shoot commemorating three years of playing the saxophone.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATHS322",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATHS322.jpg/info.json`,
            headerText:"<h5>Hans Kristian Strand</h5>",
            
            "BodyTextTitle":"Bondhusvatnet Glacier",
            bodyText:"<i>Bondhusvatnet Glacier</i><br><br>I love this peaceful place. I visit the Bondhusvatnet glacier every year hoping to get the perfect picture, and this year I finally got it.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATYA323",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATYA323.jpg/info.json`,
            headerText:"<h5>Yawar Abbas</h5>",
            
            "BodyTextTitle":"Golden Snake",
            bodyText:"<i>Golden Snake</i><br><br>In the light from this spectacular sunset the cold desert at Skardu looks like a Golden Snake.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATPK324",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATPK324.jpg/info.json`,
            headerText:"<h5>Pedro Jarque Krebs</h5>",
            
            "BodyTextTitle":"Chimpanzee",
            bodyText:"<i>Chimpanzee</i><br><br>A chimpanzee portrait.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMZ325",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMZ325.jpg/info.json`,
            headerText:"<h5>Mariah Zamora</h5>",
            
            "BodyTextTitle":"Anom Asil",
            bodyText:"<i>Anom Asil</i><br><br>This shot depicts a reversed Mona Lisa portrait, showing how real beauty is imperfect. Following in the style of Rembrandt with soft lighting emphasising the features of the person, you really see the textures of the human face.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMG326",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMG326.jpg/info.json`,
            headerText:"<h5>Marcin Giba</h5>",
            
            "BodyTextTitle":"Turquoise Lake",
            bodyText:"<i>Turquoise Lake</i><br><br>I took this photo of a turquoise lake in Poland with a drone in autumn 2021. Let us not be deceived by the blue colour of the water, or the colour of the sand. It is the result of human activity interfering with the natural environment. You cannot bathe in this lake, and the water is poisonous.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATJN327",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATJN327.jpg/info.json`,
            headerText:"<h5>Jose Pessoa Neto</h5>",
            
            "BodyTextTitle":"The Dusk",
            bodyText:"<i>The Dusk</i><br><br>When it was inaugurated in 1963, the 890 ft Arrabida Bridge in Porto, Portugal, was the largest of any concrete-arch bridges in the world . To get some good detail in the shot, I chose to capture a perspective of half the bridge. The dusk was beautiful on that day, and I waited for a big truck to enter the frame before my shutter button went ‘click’.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATAP328",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATAP328.jpg/info.json`,
            headerText:"<h5>Amal Prasad</h5>",
            
            "BodyTextTitle":"Struggle of Life",
            bodyText:"<i>Struggle of Life</i><br><br>The life of a village fisherman is not an easy one – it's a struggle for survival.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATTT329",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATTT329.jpg/info.json`,
            headerText:"<h5>Teodor Toma</h5>",
            
            "BodyTextTitle":"A Potter",
            bodyText:"<i>A Potter</i><br><br>Visiting a small village near Göreme I found him, surrounded by clay pots, from which he makes a living.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMA330",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMA330.jpg/info.json`,
            headerText:"<h5>Mazin Alhassan</h5>",
            
            "BodyTextTitle":"Stars over Yellowstone",
            bodyText:"<i>Stars over Yellowstone</i><br><br>An astrophotography shot at the Norris Geyser Basin, the hottest geyser basin in Yellowstone.  It is located near the northwest edge of Yellowstone Caldera near Norris Junction and on the intersection of three major faults.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {
            paintingId:"NATVZ331",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATVZ331.jpg/info.json`,
            headerText:"<h5>Vladimir Zivojinovic</h5>",
            bodyText:`<i>Leningrad Oblast, July 2021</i><br><br>This project is an unbiased look at Russia, and particularly at the Leningrad region, presented by the young generation of Eurasia in a modern multimedia forma, which will allow audiences around the world to learn about the distinctive character of the country. During the project the photographer got to know the ‘Russian soul’ from different angles: tranquil old villages, historical monuments and museums, cultural education, sports training and much more.<br><br>This project is an unbiased look at Russia, and particularly on the Leningrad region, presented by the young generation of Eurasia in a modern multimedia format, which will allow audiences around the world to learn about the distinctive character of the country.<br>During the project the photographer got to know “Russian soul” from different angles: old villages with a calm atmosphere, historical monuments and museums, cultural education, sports training, acquaintance, and a lot more.`,
            displayMesh:"Y",
            useMeshButton:"N"
        },

        {paintingId:"NATCT332",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATCT332.jpg/info.json`,
            headerText:"<h5>Chin Leong Teo</h5>",
            
            "BodyTextTitle":"Sumo Wrestling 10",
            bodyText:"<i>Sumo Wrestling 10</i><br><br>Two sumo wrestlers attempting to trip each other out of the ring in a split-second finish.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATFH333",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATFH333.jpg/info.json`,
            headerText:"<h5>Filip Hrebenda</h5>",
            
            "BodyTextTitle":"Born of Fire",
            bodyText:"<i>Born of Fire</i><br><br>A photo from my ‘Born of Fire’ series. It was the first photo I took on my expedition to the volcanic area of Fagradalsfjall in southwestern Iceland. Volcanoes are a rare opportunity to observe the complete transformation of a landscape. Places that were only recently valleys and meadows became hills, craters and lava fields. Being there was a great experience.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMS334",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMS334.jpg/info.json`,
            headerText:"<h5>Matjaž Šimic</h5>",
            
            "BodyTextTitle":"Wild Horses",
            bodyText:"<i>Wild Horses</i><br><br>When we visited France this summer, we saw the famous white Camargue horses. Their elegance and energy fascinated me so much that I was left speechless.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATTB335",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATTB335.jpg/info.json`,
            headerText:"<h5>Tshabalala Bongani</h5>",
            
            "BodyTextTitle":"Montsho",
            bodyText:"<i>Montsho</i><br><br>Montsho, meaning ‘black’, is a word used in South Africa to make fun of a dark-skinned person. This photograph explores the emotional effects of childhood teasing: depression and low self-esteem. Montsho opens conversations around the representation of the black body and black lives, challenging the idea that blackness is homogenous. For me this photograph is about both destruction and preservation; it’s about what we choose to embrace after going through trauma.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATMT336",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATMT336.jpg/info.json`,
            headerText:"<h5>Mariano Belmar Torrecilla</h5>",
            
            "BodyTextTitle":"Around the Moneo 1",
            bodyText:"<i>Around the Moneo 1</i><br><br>Moneo is the Murcia City Hall building. Around it you find a variety of very different people. This shot is part of a series made in that place.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATUD337",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATUD337.jpg/info.json`,
            headerText:"<h5>Uditha Prabhasha Dharmarathna</h5>",
            
            "BodyTextTitle":"Downhearted",
            bodyText:"<i>Downhearted</i><br><br>In photography, the portrait is known to capture the personality of a person or group of people, using effective lighting. I like to work with people, to capture their behaviours and and feelings through photographs. In 2021-2022, child abuse  has been atopic of conversation. I wanted to give people and idea of what it feels like, how the victims act and how they manage. That's why I took this photograph.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATPA338",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATPA338.jpg/info.json`,
            headerText:"<h5>Peter Angvarson</h5>",
            
            "BodyTextTitle":"Untitled",
            bodyText:"<i>Untitled</i><br><br>A landscape made by the lake Vanern in Sweden.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATTM339",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATTM339.jpg/info.json`,
            headerText:"<h5>Tinu Müller</h5>",
            
            "BodyTextTitle":"Out of the White Room",
            bodyText:"<i>Out of the White Room</i><br><br>Gian Simmen in his natural habitat at Grindelwald First on a perfect day in the Swiss alps.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATCW340",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATCW340.jpg/info.json`,
            headerText:"<h5>Chihao Wang</h5>",
            
            "BodyTextTitle":"Bat Cave Sink",
            bodyText:"<i>Bat Cave Sink</i><br><br>Many bats inhabited this cave in ancient times. They’ve vanished due to today’s environmental changes.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATKC341",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATKC341.jpg/info.json`,
            headerText:"<h5>Kunuch Chutmongkolporn</h5>",
            
            "BodyTextTitle":"Big Statue in the Middle of the City",
            bodyText:"<i>Big Statue in the Middle of the City</i><br><br>This photo was taken in Bangkok, Thailand, on 27 October, 2021. The Big Buddha statue is from Wat Paknam Bhasicharoen. Around the fourth week of October, the sun sets behind the statue. To get a high-impact shot, I used a super-telephoto zoom lens to compress the sun with a bird, the statue, and the city in the foreground.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATCA342",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATCA342.jpg/info.json`,
            headerText:"<h5>CIGDEM AYYILDIZ</h5>",
            
            "BodyTextTitle":"Le Mont.Saint-Michel at Sunset",
            bodyText:"<i>Le Mont.Saint-Michel at Sunset</i><br><br>For me, this piece of art on the shores of Normandy is a candidate for Eighth Wonder of the World; providing a legendary view and atmosphere especially at sunset and when the tide is low.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATSA343",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATSA343.jpg/info.json`,
            headerText:"<h5>Salem Alsawafi</h5>",
            
            "BodyTextTitle":"Movement and Stop",
            bodyText:"<i>Movement and Stop</i><br><br>This picture, taken with a drone, shows a group of flamingos moving and coming to a stop.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {paintingId:"NATJZ344",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATJZ344.jpg/info.json`,
            headerText:"<h5>Jenny Zhao</h5>",
            
            "BodyTextTitle":"Polar Bear Cub",
            bodyText:"<i>Polar Bear Cub</i><br><br>A polar bear cub staring wistfully into the distance, shot in Canada in November 2021.",
            displayMesh:"Y",
            useMeshButton:"N"},

        {
            paintingId:"NATTP345",
            videoId:"",
            videoURL: "",
            iiifSource:`${CANTALOUPE_IMAGE_SERVER}NATTP345.jpg/info.json`,
            headerText:"<h5>Thanh Nguyen Phuc</h5>",
            
            "BodyTextTitle":"Bike with Flowers",
            bodyText:"<i>Bike with Flowers</i><br><br>A hundred years ago there were just 36 streets and now there are many more, but the street culture remains strong in Hanoi. There are lots of shops in the main streets but people in the old streets prefer to get serviced by mobile street vendors. I spent a weekend following street vendors and found that they were walking or riding their bikes all day. Here is one of my favourite moments.",
            displayMesh:"Y",
            useMeshButton:"N"
        }

];

export {AnnotationsDB};
