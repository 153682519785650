import {MeshButtonBase} from "./MeshButtonBase";
import {MeshButtonsDB} from "../../../client-data/ts/MeshButtonsDB";
import {MeshButtonCallback, MeshButtonType} from "./ButtonsTypes";
import * as THREE from "three";
import {AnimatedMeshButton} from "./AnimatedMeshButton";
import {MeshButton} from "./MeshButton";
import {DEFAULT_MESH_BUTTON_TYPE} from "../../../client-data/clientOptions";

export class MeshButtonController {
    constructor() {
    }

    static getMeshButtonType = (buttonId: string): MeshButtonType => {

        const item = MeshButtonsDB.find(e => e.button_id === buttonId);

        if (item) {
            return item.meshButtonType;
        }

        return DEFAULT_MESH_BUTTON_TYPE;

    }

    static createMeshButton = (scene: THREE.Scene,
                        button: THREE.Mesh,
                        buttonId: string,
                        onClickCallback?: MeshButtonCallback,
                        onHoverOverCallback?: MeshButtonCallback,
                        onHoverOutCallback?: MeshButtonCallback): MeshButtonBase => {

        const meshType = MeshButtonController.getMeshButtonType(buttonId) ;
        if (meshType === MeshButtonType.AnimatedMeshButton) {
            console.log("createMeshButton Anim for button",buttonId)
            return new AnimatedMeshButton(scene, button, buttonId, onClickCallback, onHoverOverCallback, onHoverOutCallback);
        }
        else {
            console.log("createMeshButton basic for button",buttonId)
            return  new MeshButton(button, buttonId, onClickCallback, onHoverOverCallback, onHoverOutCallback);
        }

    }
}
