import * as THREE from "three";
import {HoverState, MeshButtonCallback} from "./ButtonsTypes";
import {MeshButtonBase} from "./MeshButtonBase";


export class MeshButton extends MeshButtonBase {


    constructor( button: THREE.Mesh,
                 buttonId: string,
                onClickCallback?: MeshButtonCallback,
                onHoverOverCallback?: MeshButtonCallback,
                onHoverOutCallback?: MeshButtonCallback) {

        super(button, buttonId, onClickCallback, onHoverOverCallback, onHoverOutCallback)


    }

   


}
