// export const ANNOTATION_TEXT_ALIGNMENT = "justify";
// export const ANNOTATION_TEXT_LINE_HEIGHT = "1.5";


import VideosMeshButtonObj from "../../components/videos/ts/VideosMeshButtonObj";

const VideosDB: VideosMeshButtonObj[] = [
    {
        meshButtonID: "R02W02V005",
        videosList: [
            {
                videoId: 'R02W02V005V01',
                videoUrl: 'R02W02V005V01.mp4',
                thumbnailUrl: `R02W02V005V01.jpg`,
                artistName: null,
                headerText: `The Climate Emergency - Professor Simon Guy`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R02W02V005V01',
            },
            {
                videoId: 'R02W02V005V02',
                videoUrl: 'R02W02V005V02.mp4',
                thumbnailUrl: `R02W02V005V02.jpg`,
                artistName: null,
                headerText: `Research - Dr Katherine Ellsworth-Krebs`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R02W02V005V02',
            },
            {
                videoId: 'R02W02V005V03',
                videoUrl: 'R02W02V005V03.mp4',
                thumbnailUrl: `R02W02V005V03.jpg`,
                artistName: null,
                headerText: `999 What’s Your Emergency? - Mark Slocombe`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R02W02V005V03',
            },
            {
                videoId: 'R02W02V005V04',
                videoUrl: 'R02W02V005V04.mp4',
                thumbnailUrl: `R02W02V005V04.jpg`,
                artistName: null,
                headerText: `People - Dr Ana Rute Costa`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R02W02V005V04',
            },
            {
                videoId: 'R02W02V005V05',
                videoUrl: 'R02W02V005V05.mp4',
                thumbnailUrl: `R02W02V005V05.jpg`,
                artistName: null,
                headerText: `Place - Zoe Hooton - HPA Architects`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R02W02V005V05',
            },
            {
                videoId: 'R02W02V005V06',
                videoUrl: 'R02W02V005V06.mp4',
                thumbnailUrl: `R02W02V005V06.jpg`,
                artistName: null,
                headerText: `Ethics - Professor Deyan Sudjic`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R02W02V005V06',
            },
            {
                videoId: 'R02W02V005V07',
                videoUrl: 'R02W02V005V07.mp4',
                thumbnailUrl: `R02W02V005V07.jpg`,
                artistName: null,
                headerText: `Radical Creativity - Professor Ruth Dalton`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R02W02V005V07',
            },
        ]
    },
    {
        meshButtonID: "R01W04V137",
        videosList: [
            {
                videoId: 'R01W04V137V01',
                videoUrl: 'R01W04V137V01.mp4',
                thumbnailUrl: `R01W04V137V01.jpg`,
                artistName: null,
                headerText: `Facilties Tour – Architecture Studios at Lancaster`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R01W04V137V01',
            },
            {
                videoId: 'R01W04V137V02',
                videoUrl: 'R01W04V137V02.mp4',
                thumbnailUrl: `R01W04V137V02.jpg`,
                artistName: null,
                headerText: `Facilties Tour – Workshop Facilities at Lancaster`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R01W04V137V02',
            },
            {
                videoId: 'R01W04V137V03',
                videoUrl: 'R01W04V137V03.mp4',
                thumbnailUrl: `R01W04V137V03.jpg`,
                artistName: null,
                headerText: `Facilties Tour – The Library Facilities at Lancaster`,
                moreInfo: null,
                bodyText: null,
                datePublished: null,
                videoElement: 'R01W04V137V03',
            },
        ]
    },
];

export default VideosDB;
