import React, {useState} from "react";
import {Toast, ToastContainer} from "react-bootstrap";

function ToastOverlay(props) {
    const [toastShow, setToastShow] = useState(true);
    return (


        <div className="toast-style">
            <Toast show={toastShow} delay={15000} autohide
                   onClose={() => setToastShow(false)}>
                <Toast.Body>
                    {props.notificationText}
                </Toast.Body>
            </Toast>
        </div>


    );
}

export default ToastOverlay;

// <div style={{position:"relative"}}>
//     <Toast style={{position:"absolute", bottom:"0", right:"0"}} show={toastShow} delay={3000} autohide