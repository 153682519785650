import * as THREE from "three";
import {Pathfinding} from "../pathfinding"
import * as TWEEN from "@tweenjs/tween.js";
import {CompletePlayerCallBack, MeshTransform, UpdatePlayerCallBack} from "./NavMeshUtils";
import {Tween} from "@tweenjs/tween.js";
import {NavMeshContainer} from "./NavMeshContainer";


const METERS_PER_SECOND = 2.0 * 100;

export class NavMeshPlayer {

    private playerPosition:THREE.Vector3;
    private groupID:number | null = null;
    private path?:Array<THREE.Vector3>;
    private pathExist = false;

    constructor(private readonly _navMeshContainer:NavMeshContainer,
                private player:THREE.Object3D,
                private pathFinder:Pathfinding,
                private zoneName:string,
                private navMeshPos: THREE.Vector3,
                private onInitCallBack:UpdatePlayerCallBack = null,
                private onStartPlayerCallBack:UpdatePlayerCallBack = null,
                private onUpdatePlayerCallBack:UpdatePlayerCallBack = null,
                private onCompletePlayerCallBack:CompletePlayerCallBack = null) {
        this.player.updateMatrixWorld();
        this.playerPosition = player.position.clone();
        this.playerPosition.y = navMeshPos.y;
        this.groupID = this.pathFinder.getGroup(this.zoneName, this.playerPosition);
        console.log("NavMeshPlayer GroupId=",this.groupID);
        console.log("NavMeshPlayer pathfinding=",this.pathFinder);
        console.log("NavMeshPlayer zoneName=",this.zoneName);
    }

    get navMeshContainer(): NavMeshContainer {
        return this._navMeshContainer;
    }

    rayTraceNaveMesh = (origin: THREE.Vector3 = this.player.position,direction:THREE.Vector3 = new THREE.Vector3(0,-1,0)) => {

        return this.navMeshContainer.rayTraceNaveMesh(origin,direction);
    }
    updateGroup = (newPos:THREE.Vector3) => {
        this.groupID = this.pathFinder.getGroup(this.zoneName,newPos);
        // console.log("navigateToTargetPos GroupId=",this.groupID);
    }

    updateGroupIDAtPlayerPos = (origin: THREE.Vector3 = this.player.position):number | null => {

        const navMeshTargetIntersection = this.rayTraceNaveMesh(origin);
        if (navMeshTargetIntersection.length > 0) {
            const pointOfTargetIntersection = navMeshTargetIntersection[0].point;
            this.updateGroup(pointOfTargetIntersection);

        }
        return this.groupID;
    }

    clampStep = (playerPos:THREE.Vector3,targetPos:THREE.Vector3, updateGroup = false):THREE.Vector3 | null  => {

        if (updateGroup) {
            this.updateGroup(targetPos);
        }

        const closestPlayerNode = this.pathFinder.getClosestNode( targetPos, this.zoneName, this.groupID!,true );
        if (closestPlayerNode) {
            // this.pathFinder.clampStep gets the clamped coordinate rounded - not accurate and can make problem for the calculation
            //of the camera height
            const clamped = new THREE.Vector3();

            this.pathFinder.clampStep(
                playerPos, targetPos, closestPlayerNode, this.zoneName, this.groupID!, clamped);
            return clamped
            //return targetPos;
        }
        else {
            return null;
        }

    }

    navigateToTargetPos = (targetPos:MeshTransform,updateGroup:boolean = false,bypassTargetIntersectionCheck = false, delay = 0): boolean => {


        let pointOfTargetIntersection: THREE.Vector3;

        if (bypassTargetIntersectionCheck) {
            pointOfTargetIntersection = targetPos.position;
        }
        else {

            const navMeshTargetIntersection = this.rayTraceNaveMesh(targetPos.position);
            if (navMeshTargetIntersection.length > 0) {
                pointOfTargetIntersection = navMeshTargetIntersection[0].point;
            }
            else {
                console.log("NavMeshPlayer.navigateToTargetPos can't find Target point of intersection with the Navmesh - ",targetPos.position);
                return false;
            }
        }


        this.player.updateMatrixWorld(true);
        this.playerPosition = this.player.position.clone();

        let navMeshPlayerIntersection = this.rayTraceNaveMesh(this.playerPosition);
        if (navMeshPlayerIntersection.length > 0) {

        }
        else {
            console.log("NavMeshPlayer.navigateToTargetPos can't find Player point of intersection with the Navmesh - ",this.playerPosition);
            return false;
        }

        targetPos.position.y = pointOfTargetIntersection.y;

        const pointOfPlayerIntersection = navMeshPlayerIntersection[0].point;
        this.playerPosition.y = pointOfPlayerIntersection.y;
        // console.log("playerPos=",this.playerPosition);
        //console.log("targetPos=",targetPos);

        if (updateGroup) {
            /*this.groupID = this.pathFinder.getGroup(this.zoneName, this.playerPosition);
            console.log("navigateToTargetPos GroupId=",this.groupID);*/
            this.updateGroup(targetPos.position);
        }
        if (this.groupID !== null) {

            this.path = this.pathFinder.findPath(this.playerPosition, targetPos.position, this.zoneName, this.groupID);

            if (this.path && this.path.length > 0) {
                this.path = this.removeDuplicateFromPath(this.path);
                const pathLength = this.path.length;
                this.pathExist = true;
                // targetPos.position.copy(this.path[0]);
                if (this.onInitCallBack) {
                    this.onInitCallBack(pointOfPlayerIntersection,0,pathLength, this.path[0].clone(),0, targetPos);
                }

                if (pathLength === 1) {
                    const distance = this.playerPosition.distanceTo(targetPos.position);
                    const targetPathPos = this.path[0].clone();
                    targetPos.position.copy(targetPathPos);
                    new TWEEN.Tween(this.playerPosition).to({
                        x:this.path[0].x,
                        y:this.path[0].y,
                        z:this.path[0].z,
                    },distance * METERS_PER_SECOND)
                        .onStart(pos => {
                            if (this.onStartPlayerCallBack) {
                                this.onStartPlayerCallBack(targetPos.position,0,1,targetPathPos, distance, targetPos);
                            }
                        })
                        .onUpdate(pos => {
                            if (this.onUpdatePlayerCallBack) {
                                this.onUpdatePlayerCallBack(pos,0,1,targetPathPos, distance, targetPos);
                            }
                        })
                        .onComplete(() => {
                            this.pathExist = false;
                            if (this.onCompletePlayerCallBack) {
                                this.onCompletePlayerCallBack(targetPos, distance);
                            }
                        })
                        .delay(delay).start();

                }
                else {
                    const tweens: Array<Tween<THREE.Vector3>> = new Array<Tween<THREE.Vector3>>(this.path.length);
                    let prevPos = this.playerPosition;


                    for (let index = 0; index < pathLength; index++) {
                        const targetPathPos = this.path[index].clone();
                        const distance = prevPos.distanceTo(targetPathPos);
                        prevPos = targetPathPos;
                        targetPos.position.copy(targetPathPos);
                        if (index < (this.path.length - 1)) {
                            tweens[index] = new TWEEN.Tween(this.playerPosition).to({
                                x: this.path[index].x,
                                y: this.path[index].y,
                                z: this.path[index].z,
                            }, distance * METERS_PER_SECOND)
                                .onStart(pos => {
                                    if (this.onStartPlayerCallBack) {
                                        this.onStartPlayerCallBack(targetPathPos,index,pathLength,targetPathPos,distance, targetPos);
                                    }
                                })
                                .onUpdate(pos => {
                                    if (this.onUpdatePlayerCallBack) {
                                        this.onUpdatePlayerCallBack(pos,index,pathLength,targetPathPos,distance, targetPos);
                                    }
                                })

                        }
                        else {
                            tweens[index] = new TWEEN.Tween(this.playerPosition).to({
                                x: this.path[index].x,
                                y: this.path[index].y,
                                z: this.path[index].z,
                            }, distance * METERS_PER_SECOND)
                                .onStart(pos => {
                                    if (this.onStartPlayerCallBack) {
                                        this.onStartPlayerCallBack(targetPathPos,index,pathLength,targetPathPos,distance,targetPos);
                                    }
                                })
                                .onUpdate(pos => {
                                    if (this.onUpdatePlayerCallBack) {
                                        this.onUpdatePlayerCallBack(pos,index,pathLength,targetPathPos,distance, targetPos);
                                    }
                                })
                                .onComplete(() => {
                                    this.pathExist = false;
                                    if (this.onCompletePlayerCallBack) {
                                        this.onCompletePlayerCallBack(targetPos, distance);
                                    }
                                })

                        }

                    }
                    for (let index = 0; index < this.path.length -1; index++) {
                        tweens[index].chain(tweens[index+1]);
                    }
                    tweens[0].delay(delay).start();
                }
            }
            else {
                console.error("Path not found");
                return false;
            }
        }
        else {
            console.error("Group Is is null ");
            return false;
        }

        return true;

    }

    getPathForTargetPos = (targetPos:MeshTransform,updateGroup:boolean = false,bypassTargetIntersectionCheck = false): Array<THREE.Vector3> | null => {

        let thePath: Array<THREE.Vector3> | null = null;

        let pointOfTargetIntersection: THREE.Vector3;

        if (bypassTargetIntersectionCheck) {
            pointOfTargetIntersection = targetPos.position;
        }
        else {

            const navMeshTargetIntersection = this.rayTraceNaveMesh(targetPos.position);
            if (navMeshTargetIntersection.length > 0) {
                pointOfTargetIntersection = navMeshTargetIntersection[0].point;
            }
            else {
                console.log("NavMeshPlayer.getPathForTargetPos can't find Target point of intersection with the Navmesh - ",targetPos.position);
                return thePath;
            }
        }


        this.player.updateMatrixWorld(true);
        this.playerPosition = this.player.position.clone();

        let navMeshPlayerIntersection = this.rayTraceNaveMesh(this.playerPosition);
        if (navMeshPlayerIntersection.length > 0) {

        }
        else {
            console.log("NavMeshPlayer.getPathForTargetPos can't find Player point of intersection with the Navmesh - ",this.playerPosition);
            return thePath;
        }

        targetPos.position.y = pointOfTargetIntersection.y;

        const pointOfPlayerIntersection = navMeshPlayerIntersection[0].point;
        this.playerPosition.y = pointOfPlayerIntersection.y;


        if (updateGroup) {

            this.updateGroup(targetPos.position);
        }
        if (this.groupID !== null) {

            thePath = this.pathFinder.findPath(this.playerPosition, targetPos.position, this.zoneName, this.groupID);

            if (thePath && thePath.length > 0) {
                thePath = this.removeDuplicateFromPath(thePath);
                return thePath;

            }
            else {
                console.error("getPathForTargetPos Path not found");
                return thePath;
            }
        }
        else {
            console.error("getPathForTargetPos Group Is is null ");
            return thePath;
        }

    }

    update = () => {
        TWEEN.update();
    }

    removeDuplicateFromPath = (pathArray: Array<THREE.Vector3>): Array<THREE.Vector3> => {


        const newPath: Array<THREE.Vector3> = [];
        let prevPos = pathArray[0];
        newPath.push(prevPos);
        if (pathArray.length === 1) return newPath;
        for (let x = 1; x < pathArray.length; x++) {
            //console.log(`pathArray[${x}]=${pathArray[x]}`);
            const vec = prevPos.clone().sub(pathArray[x]);
            if (vec.lengthSq() < 1) continue;
            prevPos = pathArray[x];
            newPath.push(pathArray[x]);
        }

        return newPath;
    }
}
