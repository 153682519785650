import VideoContainer from "../../components/annotations/VideoContainer";
import VideoDisplay from "../../components/annotations/VideoDisplay";
import VideoRecord from "../../components/videos/ts/VideoRecord";

export class LancasterVideoController {

    private vd_R02W02V005: VideoDisplay;
    private vd_R01W04V137: VideoDisplay;

    constructor(private videoContainer: VideoContainer) {

        this.vd_R02W02V005 = this.videoContainer.getVideoDisplay("R02W02V005V01");
        this.vd_R01W04V137 = this.videoContainer.getVideoDisplay("R01W04V137V01");

    }

    public onSelectVideoThumbNail = (videoRecord: VideoRecord, meshBtnId: string) => {
        console.log("onSelectVideoThumbNail=", videoRecord, meshBtnId);
        let vDisplay: VideoDisplay | null = null;

        if (meshBtnId === 'R02W02V005') {
            vDisplay = this.vd_R02W02V005;
        }
        else  if (meshBtnId === 'R01W04V137') {
            vDisplay = this.vd_R01W04V137;
        }

        if (vDisplay) {
            vDisplay.PauseVideo();

            setTimeout(() => {
                if (vDisplay) {
                    vDisplay.resetVideoSource(videoRecord.videoUrl);
                    vDisplay.playVideo();
                }
            }, 2000);
        }


    }
}
