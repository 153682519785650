import React from "react";
import VideoComponent from "../util/VideoComponent";

const VideosComponent = (props) => {
        return (
            <div>
                    {/*<VideoComponent id="S02P07V01" src={process.env.PUBLIC_URL + `/videos/S02P07V01.mp4`}/>*/}

                    {/*Audio*/}
                    {/*<VideoComponent id="S02P07A01" src={process.env.PUBLIC_URL + `/audio/S02P07A01.mp3`} audio="Y"/>*/}
            </div>
        )
}

export default VideosComponent;
