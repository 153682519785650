import * as THREE from 'three';
import SpriteAnnotation from './SpriteAnnotation'
import {Vector3} from "three";
import {CSS3DObject} from "three/examples/jsm/renderers/CSS3DRenderer";
import {AnnotationsDB, ANNOTATION_CONTACT_TEXT} from '../../client-data/AnnotationDB'
import {ANNOTATION_LAYER_CHANEL} from "../../client-data/GlobalConstants";
import {
    DEFAULT_SPRITE_IMAGE_URL,
    SPRITE_COLOR,
    SPRITE_TEXTURE_ENCODING,
    SPRITE_TONEMAPPED
} from "../../client-data/clientOptions";
import ReactGa from "react-ga";


export const ANNOTATION_DOUBLE_SCALE = new THREE.Vector3(2, 2, 1);

class AnnotationContainer {
    constructor(scene, camera, css3dScene, callAnnotationModal, meshButtonContainer) {

        this._meshButtonContainer = meshButtonContainer;
        this.isTouchScreen = 'ontouchstart' in window;
        this.selectedObject = null;
        this.scene = scene;
        this.camera = camera;
        this.css3dScene = css3dScene;
        this.camera.layers.enable(ANNOTATION_LAYER_CHANEL);
        this.annotationGroup = new THREE.Group();
        this.annotationGroup.name = "Annotation-Sprite-Group"
        this.annotationGroup.matrixAutoUpdate = false;
        this.annotationGroup.updateMatrix();
        scene.add(this.annotationGroup);

        this.onDocumentMouseDown = this.onDocumentMouseDown.bind(this);
        this.addAnnotationToMesh = this.addAnnotationToMesh.bind(this);


        this.annatotions = {};
        this.materialCache = {};
        this.originalAnnotScale = new THREE.Vector3();

        const spriteMap = new THREE.TextureLoader().load(process.env.PUBLIC_URL + DEFAULT_SPRITE_IMAGE_URL);
        spriteMap.encoding = SPRITE_TEXTURE_ENCODING;
        // const spriteMap = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/assets/sprites/AnnotationsI.svg');

        this.spriteMaterial = new THREE.SpriteMaterial({map: spriteMap,  color: new THREE.Color("rgb(255,255,255)"),fog: false,name:`Annotation-Dflt-Mat`});
        this.spriteMaterial.toneMapped = SPRITE_TONEMAPPED;

        /* const spriteMapVideo = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/assets/sprites/AnnotationsVideo.svg');

         this.spriteMaterialVideo = new THREE.SpriteMaterial({map: spriteMapVideo, color: 0xffffff, fog: true});*/

        //for preventing the annotation from flickering (closing and opening) while hovering on it
        this.lastSelectedAnnot = null;

        this.activeAnnotation = null;

        this.callAnnotationModal = callAnnotationModal;
    }

    removeMouseListeners = ()=> {
        window.removeEventListener("click", this.onDocumentMouseDown);
    }

    addMouseListeners = ()=> {
        window.addEventListener("click", this.onDocumentMouseDown, false);
    }



    resetScene = (scene)=> {
        this.scene = scene;
        this.scene.add(this.annotationGroup);
        this.annotationGroup.updateMatrix();
    }

    create3dAnnotation = (mesh, mediaRecord) => {
        let annotation = null;
        let sprite = null;


        const displayMesh = mediaRecord.displayMesh;
        const displayHeight = mediaRecord.displayHeight;

        /*if (mediaRecord.isVideo) {
            sprite = new THREE.Sprite(this.spriteMaterial);
            annotation = new SpriteAnnotation(mesh, sprite, (displayMesh === 'Y'), true);

        } else {
            sprite = new THREE.Sprite(this.spriteMaterial);
            annotation = new SpriteAnnotation(mesh, sprite);
        }*/
        if (mediaRecord.spriteImageURL) {
            let spriteMat;
            if (!this.materialCache[mediaRecord.spriteImageURL]) {
                //console.log("AnnotationContainer will cache mat:",mediaRecord.spriteImageURL);
                const spriteMap = new THREE.TextureLoader().load(process.env.PUBLIC_URL + mediaRecord.spriteImageURL);
                spriteMap.encoding = SPRITE_TEXTURE_ENCODING;

                spriteMat = new THREE.SpriteMaterial({map: spriteMap, color: SPRITE_COLOR, fog: true,name:`Annot-${mediaRecord.paintingId}`});
                spriteMat.toneMapped = SPRITE_TONEMAPPED;
                this.materialCache[mediaRecord.spriteImageURL] = spriteMat;
                spriteMap.dispose();
            }
            else {
                //console.log("AnnotationContainer use cache mat:",mediaRecord.spriteImageURL);
                spriteMat = this.materialCache[mediaRecord.spriteImageURL];
            }

            sprite = new THREE.Sprite(spriteMat);
            sprite.name = `Sprite-${mediaRecord.paintingId}`;
            annotation = new SpriteAnnotation(mesh, sprite, (displayMesh === 'Y'),mediaRecord);

        }
        else {
            sprite = new THREE.Sprite(this.spriteMaterial);
            sprite.name = `Sprite-${mediaRecord.paintingId}`;
            annotation = new SpriteAnnotation(mesh, sprite, (displayMesh === 'Y'),mediaRecord);
        }

        annotation.height = 1000 * displayHeight / 100;

        return {annotation, sprite}
    }

    addAnnotationToMesh(mesh, mediaRecord) {
        //console.log(`addAnnotationToMesh ${mediaRecord.paintingId}  of useMeshButton = ${mediaRecord.useMeshButton}`);
        if (mediaRecord.useMeshButton === 'Y') {
           // console.log(`MeshButton for annotation ${mediaRecord.paintingId} will be created`)
            let annotBtn = this._meshButtonContainer.getMeshButton(mediaRecord.paintingId);
            if (annotBtn) {
                // console.info(`MeshButton for annotation ${mediaRecord.paintingId} is found and created`)
                annotBtn.onClickCallback =   this.meshButtonCB;
                return;
            }
            else {
               console.warn(`Can't find mesh button for the annotation ${mediaRecord.paintingId}. Will use Sprite button`);
            }
        }

        let {annotation, sprite} = this.create3dAnnotation(mesh, mediaRecord);

        sprite.layers.set(ANNOTATION_LAYER_CHANEL);
        //this.scene.add(sprite);
        this.annatotions[sprite.id] = annotation;
        this.annotationGroup.add(sprite);
        this.annotationGroup.updateMatrix();
    }

    //call back for the MeshButton
    meshButtonCB = (button_id) => {
        // console.log("Annotation MeshButton is pressed-", button_id);
        if (this.callAnnotationModal) {
            //Google Analytics
            ReactGa.event({
                category: 'Annotation MeshButton is pressed',
                action: `User clicked on ${button_id}`,
            });
            //Google Analytics
            this.callAnnotationModal(button_id);

        }
    }

    annotationSelected(annot) {

        //use modal annotation
        if (this.callAnnotationModal) {
            this.callAnnotationModal(annot.paintingId);
            return;
        }

        this.lastSelectedAnnot = annot;
        if (annot.selected) {

            this.closeAnnotation(annot);
        } else {

            this.closeActiveAnnotation();
            const cssObj = this.create3dPage(
                700, annot.height,
                new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(0, 0, 0),
                '/AnnotationPage/' + annot.paintingId);

            cssObj.applyQuaternion(annot.css3dRotation);
            cssObj.position.copy(annot.annotationWorldPos)
            annot.css3dAnnot = cssObj;
            annot.css3dAnnot.element.style.visibility = 'visible';
            annot.selected = true;
            this.activeAnnotation = annot;
        }


    }

    annotationUnSelected(annot) {
        this.lastSelectedAnnot = null;
    }

    hoverOver = (annot) => {

        document.body.style.cursor = "pointer";

    }

    hoverOut = (annot) => {
        document.body.style.cursor = "default";
    }

    closeAnnotation = (annotation) => {
        annotation.css3dAnnot.element.style.visibility = 'hidden';
        //need to remove the video, because if video is playing then it will continue to play sound even after getting hidden
        // if (this.activeAnnotation.isVideo) {
        this.css3dScene.remove(annotation.css3dAnnot);
        annotation.css3dAnnot = null;
        annotation.selected = false;
        this.activeAnnotation = null;
    }

    closeActiveAnnotation = () => {

        if (this.activeAnnotation) {
            this.closeAnnotation(this.activeAnnotation)

        }

    }

    onDocumentMouseMove = (res) => {

        if ( res.object.id in this.annatotions) {
            const buttonId = res.object.id;
            if (this.selectedObject) {
                if (this.selectedObject !== buttonId) {
                    const old_id = this.annatotions[this.selectedObject];
                    const new_id = this.annatotions[buttonId];
                    this.selectedObject = buttonId;
                    return {
                        result: true,
                        callBack: () => {
                            this.hoverOut(old_id);
                            this.hoverOver(new_id);
                        }
                    }
                } else {
                    return {
                        result: true,
                        callBack: null
                    }
                }
            } else {

                this.selectedObject = buttonId;
                return {
                    result: true,
                    callBack: () => {
                        this.hoverOver(buttonId);
                    }
                }
            }
        } else {

            if (this.selectedObject) {
                const old_id = this.annatotions[this.selectedObject];
                this.selectedObject = null;
                return {
                    result: false,
                    callBack: () => {
                        this.hoverOut(this.annatotions[old_id]);
                    }
                }
            }
            return {
                result: false,
                callBack: null
            }
        }
    }

    onDocumentMouseDown(res) {

        if (res.object.id in this.annatotions) {
            const sel_id = this.annatotions[res.object.id];
            return {
                result: true,
                callBack: () => {
                    this.annotationSelected(sel_id);
                }
            }
        }
        return {
            result: false,
            callBack: null
        }
    }

    createCssObject(w, h, position, rotation, url) {

        const html = [

            '<div  style="width:' + w + 'px; height:' + h + 'px;">',
            '<iframe src="' + url + '" width="' + w + '" height="' + h + '">',
            '</iframe>',
            '</div>'

        ].join('\n');
        const imageUrl = process.env.PUBLIC_URL + "/images/01_PreloadBackground.png";
        const headText = 'Sara Mohr-Pietsch with an adventurous';
        const bodyText = `This text is the content of the box. We have added a 50px padding, 20px margin and a 15px green border. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;
        const moreText = 'Some more text';



        const html3 = `<div style=" width: ${w}px; height: ${h}px;"> <iframe src="${url}" width="100%" height="100%" ></iframe> </div>`;


        const div = document.createElement('div');
        this.div = div;

        div.innerHTML = html3;
        div.style.visibility = 'hidden';

        const cssObject = new CSS3DObject(div);

        cssObject.position.x = 0;
        cssObject.position.y = 0;
        cssObject.position.z = 0;

        cssObject.rotation.x = rotation.x;
        cssObject.rotation.y = rotation.y;
        cssObject.rotation.z = rotation.z;

        cssObject.scale.set(.001, .001, .001);

        return cssObject;
    }


    create3dPage(w, h, position, rotation, url) {


        const cssObject = this.createCssObject(
            w, h,
            position,
            rotation,
            url);

        this.css3dScene.add(cssObject);
        return cssObject;
    }

    get annotationContainerGroup() {
        return this.annotationGroup;
    }

    dispose = () => {
        // this.annotationGroup.remove(...this.annotationGroup.children);
        this.annotationGroup.traverse((object) => {
            object.remove(...object.children);
        });
        this.annatotions = {};
    }

    clearMaterialCache = () => {
        //console.log("AnnotationContainer clearMaterialCache ");
        this.materialCache = {};
    }
}

export default AnnotationContainer;
