import {MeshButtonDBType, MeshButtonType} from "../../components/annotations/ts/ButtonsTypes";

export const  MeshButtonsDB: Array<MeshButtonDBType> = [
    // {
    //     button_id: '',
    //     meshButtonType: MeshButtonType.BasicMeshButton
    // },
    // {
    //     //This is an example of a mesh button with a special animation
    //     button_id: 'LiftTopButton',
    //     meshButtonType: MeshButtonType.AnimatedMeshButton
    // },

    // {
    //     button_id: 'R02W02V005',
    //     meshButtonType: MeshButtonType.AnimatedMeshButton
    // },
]
