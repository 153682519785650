import {Euler, Vector3, Scene} from "three";

const FLOOR_NUMBER = {
    BOTTOM_FLOOR: 1,
    TOP_FLOOR: 2,
};
// Here we split by Artists Names
//ChatRoom01
//ScreenStageLeft
const CameraLocationsDB = [

    // {
    //     cameraLocationId: '',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: '',
    //     cameraPosition: null,
    //     cameraRotation: null,
    //     displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    // },
    {
        cameraLocationId: 'INTCL362',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Welcome',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'PROCL361',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Professional & Open competitions',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'ARCCL346',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Architecture & Design',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'CRECL347',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Creative',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'DOCCL348',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Documentary Projects',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'ENVCL349',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Environment',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'LANCL350',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Landscape',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'POFCL351',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Portfolio',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'SPOCL353',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Sport',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
    {
        cameraLocationId: 'WILCL355',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Wildlife & Nature',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
    {
        cameraLocationId: 'PORCL352',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Portraiture',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'STICL354',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Still Life',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'STUCL364',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Student Competition',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
{
        cameraLocationId: 'LATCL356',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Professional Latin America Awards',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
{
        cameraLocationId: 'SAFCL358',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Alpha Female Award',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: 'NATCL360',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'National Awards',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },
    {
        cameraLocationId: 'THACL363',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Exit',
        cameraPosition: null,
        cameraRotation: null,
        displayMesh: 'N', //display/hide mesh - applies only on locations set in Blender. Default is 'Y'
    },

];

export {CameraLocationsDB, FLOOR_NUMBER};
