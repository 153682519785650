import React, {useState} from 'react';
import ThreeGLTFLoader from "../../components/three-gltf-loader"
import EnterAppModal from "../../components/modals/EnterAppModal";
import {useSearchParams} from "react-router-dom";


function GalleryEntry(props) {
    const [enterAppFlag, setEnterAppFlag] = useState(false);
    // useGUI=Y
    const [searchParams, setSearchParams] = useSearchParams();

    const enterApp = (evt) => {
        setEnterAppFlag(true);
    }



    return (
        <div>
            {/*{this.state.enterApp ? <ThreeCubeSample/> : null}*/}
            {/*<ThreeBuildingSample/>*/}
            {/*<CircleJoystick/>*/}
            {/*<ThreeGLTFLoader/>*/}
            {enterAppFlag ? <ThreeGLTFLoader useGUI={searchParams.get("useGUI")}/> : null}
            {!enterAppFlag ? <EnterAppModal enterApp={enterApp}/> : null}
            {/*<AnnotationPage paintingId="W01P01"/>*/}
        </div>
    )
}

export default GalleryEntry;
