import * as THREE from "three";
import {CSS3DObject} from "three/examples/jsm/renderers/CSS3DRenderer";
import {
    ANNOTATION_LAYER_CHANEL,
    ANNOTATION_LOCATION, ANNOTATION_PARENT,
    VIDEO_LAYER_CHANEL,
    VIDEO_PLAYING_MODE, VIDEO_SRC_TYPE
} from "../../client-data/GlobalConstants";
import {isIOS,isSafari,isChrome,isAndroid, isFirefox} from 'react-device-detect';
import {
    DEFAULT_AUDIO_ANNOTATION_LOCATION,
    DEFAULT_AUDIO_ANNOTATION_LOCATION_HORIZ_OFFSET, DEFAULT_AUDIO_ANNOTATION_LOCATION_VERT_OFFSET,
    DEFAULT_AUDIO_ANNOTATION_LOCATION_Z_OFFSET, DEFAULT_AUDIO_ANNOTATION_SCALE,
    DEFAULT_VIDEO_ANNOTATION_LOCATION,
    DEFAULT_VIDEO_ANNOTATION_LOCATION_HORIZ_OFFSET,
    DEFAULT_VIDEO_ANNOTATION_LOCATION_VERT_OFFSET,
    DEFAULT_VIDEO_ANNOTATION_LOCATION_Z_OFFSET,
    DEFAULT_VIDEO_ANNOTATION_SCALE,
    VIDEO_SPRITE_COLOR,
    VIDEO_SPRITE_TEXTURE_ENCODING,
    VIDEO_SPRITE_TONEMAPPED
} from "../../client-data/clientOptions";
import * as Hls from "hls.js";
import {AudioController} from "../../Audio/ts/AudioController";
import {RenderingValidationSingleton} from "../Rendering/ts/RenderingValidationSingleton";



const BUTTON_STATE_PLAY = 0;
const BUTTON_STATE_PAUSE = 1;

class VideoDisplay {


    videoScreen;
    videoElm;
    _isPlaying = false;
    videoButton;
    videoScreenInit = false;
    _canPlay = false;
    _autoPlayLoopCounter = 0;
    _videoMeshButton = null;
    _useVideoMeshButton = false;
    _useVideoGroupMeshButton = false;
    _camera = null;
    _redisplayMeshAfterVideoEnd = false;
   // _videoScreenMaterial = null;
    _canAutoPlay = true; // this will depend on the status of the mute menu icon status - only for auto players

    constructor(mediaRecord, mesh, scene, css3dScene, camera,videosGroup,materialPlay,materialPause, videoMeshButton, videoMeshGroupButton, meshContainer) {

        this._meshContainer = meshContainer;
        this._videoScreenMaterial = null;
        this._camera = camera;
        this._useVideoGroupMeshButton = mediaRecord.useVideoGroupMeshButton === 'Y';
        this._videoMeshButton = videoMeshGroupButton;
        if (this._videoMeshButton && this._useVideoGroupMeshButton) {
            this._videoMeshButton.addCallBack(this.videoMeshGroupButtonOnClick,mediaRecord.paintingId);
        }

        if (!this._useVideoGroupMeshButton) {
            this._useVideoMeshButton = mediaRecord.useVideoMeshButton === 'Y';

            this._videoMeshButton = videoMeshButton;
            if (this._videoMeshButton && this._useVideoMeshButton) {
                this._videoMeshButton.onClickCallback = this.videoMeshButtonOnClick;
            }
        }

        this.mediaRecord = mediaRecord;
        this.mesh = mesh;
        this._mediaId = mediaRecord.paintingId;
        this.scene = scene;
        this.css3dScene = css3dScene;

        this.spriteMaterialPlay = materialPlay;
        this.spriteMaterialPause = materialPause;


        this.videosGroup = videosGroup;

        if (this.mediaRecord.isAudioPlayer) {
            this.calcAudioPosition();
        } else {

            this.calcPosition();
        }
    }

    static getSpriteDefaultPos(boxSize,mediaRecord) {

        const defPos = mediaRecord.isAudioPlayer ?  (mediaRecord?.video_button_location || DEFAULT_AUDIO_ANNOTATION_LOCATION || ANNOTATION_LOCATION.TOP_RIGHT) :(mediaRecord?.video_button_location || DEFAULT_VIDEO_ANNOTATION_LOCATION || ANNOTATION_LOCATION.TOP_RIGHT);
        let offsetX = mediaRecord.isAudioPlayer ?   (mediaRecord?.video_button_horiz_offset || DEFAULT_AUDIO_ANNOTATION_LOCATION_HORIZ_OFFSET || 0) :(mediaRecord?.video_button_horiz_offset || DEFAULT_VIDEO_ANNOTATION_LOCATION_HORIZ_OFFSET || 0);
        let offsetY = mediaRecord.isAudioPlayer ?   (mediaRecord?.video_button_vert_offset || DEFAULT_AUDIO_ANNOTATION_LOCATION_VERT_OFFSET || 0) :(mediaRecord?.video_button_vert_offset || DEFAULT_VIDEO_ANNOTATION_LOCATION_VERT_OFFSET || 0);
        let offsetZ = mediaRecord.isAudioPlayer ?   (mediaRecord?.video_button_z_offset || DEFAULT_AUDIO_ANNOTATION_LOCATION_Z_OFFSET || .1) :(mediaRecord?.video_button_z_offset || DEFAULT_VIDEO_ANNOTATION_LOCATION_Z_OFFSET || .1);


        switch (defPos) {
            case ANNOTATION_LOCATION.TOP_RIGHT:
                offsetX = (offsetX + boxSize.x / 2);
                offsetY = (offsetY + boxSize.y / 2);
                break;

            case ANNOTATION_LOCATION.CENTER_RIGHT:
                offsetX = (offsetX + boxSize.x / 2);
                break;

            case ANNOTATION_LOCATION.BOTTOM_RIGHT:
                offsetX = (offsetX + boxSize.x / 2);
                offsetY = (offsetY + boxSize.y / 2) * -1;
                break;

            case ANNOTATION_LOCATION.BOTTOM_CENTER:
                offsetY = (offsetY + boxSize.y / 2) * -1;
                break;

            case ANNOTATION_LOCATION.BOTTOM_LEFT:
                offsetX = (offsetX + boxSize.x / 2) * -1;
                offsetY = (offsetY + boxSize.y / 2) * -1;
                break;

            case ANNOTATION_LOCATION.CENTER_LEFT:
                offsetX = (offsetX + boxSize.x / 2) * -1;
                break;

            case ANNOTATION_LOCATION.TOP_LEFT:
                offsetX = (offsetX + boxSize.x / 2) * -1;
                offsetY = (offsetY + boxSize.y / 2);
                break;

            case ANNOTATION_LOCATION.TOP_CENTER:
                offsetY = (offsetY + boxSize.y / 2);
                break;

            case ANNOTATION_LOCATION.CENTER:
                break;

        }

        return (new THREE.Vector3(offsetX, offsetY,offsetZ ))

    }

    calcPosition = () => {
        const boundingBox = this.mesh.geometry.boundingBox;
        const boxSize = boundingBox.getSize(new THREE.Vector3());
        const videoRotation = this.mesh.quaternion.clone();
        let worldPos = new THREE.Vector3();
        let worldPosVideoButton = new THREE.Vector3();

        this._redisplayMeshAfterVideoEnd = (this.mediaRecord.redisplayMeshAfterVideoEnd === 'Y') && !this.mediaRecord.videoLoop;

        this.videoElm = document.getElementById(this.mediaRecord.standaloneVideoElementId);
        if (isIOS) {

          //  this.videoElm.onloadedmetadata = this.canPlay;
            this.videoElm.addEventListener("loadedmetadata",this.canPlay);

        } else {
            // console.log("[VideoDisplay]", this.videoElm, this.canPlay);
           // this.videoElm.oncanplay = this.canPlay;
            this.videoElm.addEventListener("canplay",this.canPlay);

        }

        this.videoElm.addEventListener("ended",this.ended);
        this.videoElm.addEventListener("pause",this.pause);
        this.videoElm.addEventListener("play",this.play);
       /* this.videoElm.onended = this.ended;
        this.videoElm.onpause = this.pause;
        this.videoElm.onplay = this.play;*/
        this.videoElm.loop = this.mediaRecord.videoLoop;




        if (this.mediaRecord.videoSrcType === VIDEO_SRC_TYPE.FROM_FILE) {
            this.videoElm.src = this.mediaRecord.videoURL;
        }
        else if (this.mediaRecord.videoSrcType === VIDEO_SRC_TYPE.FROM_STREAM_HLS) {

            console.log("HLS pre-init");
            if (Hls.isSupported()) {
                console.log("HLS init",this.mediaRecord.videoStreamURL);
                const hls = new Hls();
                hls.loadSource(this.mediaRecord.videoStreamURL);
                hls.attachMedia(this.videoElm);
                const self = this;
                 hls.on(Hls.Events.MANIFEST_PARSED, function() {
                     console.log("Hls.Events.MANIFEST_PARSED:",self.mediaRecord.videoStreamURL);
                     //self.videoElm.play();
                 });
            }
            else {
                console.log("HLS is not supported");
            }
        }
        const texture = new THREE.VideoTexture(this.videoElm);
        texture.minFilter = THREE.NearestFilter;
        texture.magFilter = THREE.NearestFilter;
        texture.encoding = this.mediaRecord.videoTextureEncoding;

        if (isFirefox) {
            //Switch Video texture format for Firefox  to RGBAFormat due to bug in firefox
            //need to check from tim to time if this issue been resolved with firefox
            // bug report https://bugzilla.mozilla.org/show_bug.cgi?id=1708491
            // https://bugzilla.mozilla.org/show_bug.cgi?id=1486454
            texture.format = THREE.RGBAFormat;
            console.log("Switch Video texture format for Firefox  to RGBAFormat due to bug in firefox")
        }
       // texture.format = THREE.LinearEncoding;

        const parameters = {map: texture, name:`MeshBasic-${this._mediaId}`};

        const geometry = new THREE.PlaneBufferGeometry(boxSize.x, boxSize.y);

        this._videoScreenMaterial = new THREE.MeshBasicMaterial(parameters);
        this._videoScreenMaterial.toneMapped = false;
        this.videoScreen = new THREE.Mesh(geometry, this._videoScreenMaterial);
        this._meshContainer.addMesh(this.videoScreen);

        const offsetPos = new THREE.Vector3(0, 0, boxSize.z + 0.001);
        const offsetPosVideoButton = VideoDisplay.getSpriteDefaultPos(boxSize,this.mediaRecord); //new THREE.Vector3(0, -boxSize.y / 2 - .05, .1);
        this.mesh.add(this.videoScreen);

        this.videoScreen.position.copy(offsetPosVideoButton);
        this.videoScreen.getWorldPosition(worldPosVideoButton);
        this.videoScreen.position.copy(offsetPos);
        this.videoScreen.getWorldPosition(worldPos);
        this.videoScreen.visible = false;

        if (!(this._useVideoMeshButton || this._useVideoGroupMeshButton) &&  (this.mediaRecord.displayAudioSprite === 'Y') ) {
            if (this.mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY || this.mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_WITH_BUTTONS) {
                const videoButtonPos = this.mediaRecord.videoButtonPos || worldPosVideoButton.clone();
                this.videoButton = this.createVideoButton(videoButtonPos, boxSize);
                this.videosGroup.add(this.videoButton);
                this.videosGroup.updateMatrix();
                this.videoButton.matrixAutoUpdate = false;
                this.videoButton.updateMatrix();
            }
        }

        //if (this.mediaRecord.displayMesh === 'N') {
        this.mesh.remove(this.videoScreen);
        this.videoScreen.position.copy(worldPos);
        this.videoScreen.applyQuaternion(videoRotation);
        this.scene.add(this.videoScreen);

        //}

        this.videoScreen.matrixAutoUpdate = false;
        this.videoScreen.updateMatrix();
    }

    calcAudioPosition = () => {

        let  audioCtrl = null;

        this.videoElm = document.getElementById(this.mediaRecord.standaloneVideoElementId);
        // console.log("calcAudioPosition audio element id", this.mediaRecord.standaloneVideoElementId);
        if (isIOS || isSafari) {
            console.log("calcAudioPosition isAndroid or Ios");
            this.videoElm.onloadedmetadata = this.canPlay;

        } else {
            // console.log("[VideoDisplay]", this.videoElm, this.canPlay);
            this.videoElm.oncanplay = this.canPlay;

        }

        this.videoElm.onended = this.ended;
        this.videoElm.onpause = this.pause;
        this.videoElm.onplay = this.play;
        this.videoElm.loop = this.mediaRecord.videoLoop;


        if (this.mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS || this.mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_WITH_BUTTONS) {
            this.videoElm.autoplay = true;
            if ( isChrome || isAndroid) {
                audioCtrl = new AudioController(this.scene, this._camera,this.mediaRecord.standaloneVideoElementId);
            }
        }

        if (this.mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_NOT_AUTO_PLAY || this.mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_WITH_BUTTONS) {

            if (this.mediaRecord.displayAudioSprite === 'Y') {
                const boundingBox = this.mesh?.geometry.boundingBox;
                const boxSize = boundingBox?.getSize(new THREE.Vector3());
                let worldPosAudioButton = new THREE.Vector3();
                let offsetPosAudioButton = new THREE.Vector3();

                if (this.mediaRecord.annotationParent === ANNOTATION_PARENT.PARENT_NONE) {
                    const videoButtonPos = this.mediaRecord.videoButtonPos;
                    this.videoButton = this.createVideoButton(videoButtonPos, null);
                    this.videosGroup.add(this.videoButton);
                    this.videosGroup.updateMatrix();
                } else {
                    this.videoButton = new THREE.Sprite(this.spriteMaterialPlay);
                    this.videoButton.userData = this.mediaRecord.paintingId;


                    if (this.mediaRecord.videoButtonPos) {
                        worldPosAudioButton = this.mediaRecord.videoButtonPos;

                    } else {
                        this.mesh.add(this.videoButton);
                        offsetPosAudioButton = VideoDisplay.getSpriteDefaultPos(boxSize, this.mediaRecord);
                        this.videoButton.position.copy(offsetPosAudioButton);
                        this.videoButton.getWorldPosition(worldPosAudioButton);
                    }

                    this.videosGroup.add(this.videoButton);
                    this.videosGroup.updateMatrix();
                    this.videoButton.position.copy(worldPosAudioButton.clone());
                    const spriteScale = this.mediaRecord.videoButtonScale || (this.mediaRecord.isAudioPlayer ? DEFAULT_AUDIO_ANNOTATION_SCALE : DEFAULT_VIDEO_ANNOTATION_SCALE);
                    console.log("sprite scale=", spriteScale, this.mediaRecord.videoButtonScale);
                    this.videoButton.scale.copy(spriteScale);
                    this.videoButton.layers.set(VIDEO_LAYER_CHANEL);

                }

                this.videoButton.matrixAutoUpdate = false;
                this.videoButton.updateMatrix();
            }
        }

        if (this.mediaRecord.displayMesh === 'N') {
            if (this.mesh) {

                this.mesh.visible = false;
            }
        }
    }

    canPlay = () => {
        // console.log("========Canplay 1",this.mediaRecord.videoAutoPlay,this.mediaRecord.standaloneVideoElementId);
        this._canPlay = true;
        if (this.mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS || this.mediaRecord.videoAutoPlay === VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_WITH_BUTTONS) {
            // console.log("========Canplay 2");
            if (this.mediaRecord.videoAutoPlayDelay) {
                setTimeout(()=> {
                    // console.log("========Canplay delay:",this.mediaRecord.videoAutoPlayDelay);

                    if (this.canAutoPlay ) this.autoPlay()
                },this.mediaRecord.videoAutoPlayDelay)
            }
            else {
                // console.log("========Canplay autoplay");
                if (this.canAutoPlay ) this.autoPlay();
            }
        }
        // console.log("========Canplay end");
    }

    ended = () => {
        this._isPlaying = false;
        if (this.mediaRecord.videoAutoPlay !== VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS) {


            this.videoMeshButtonPlayState();
        }
        if (!this.mediaRecord.isAudioPlayer) {
            RenderingValidationSingleton.getInstance().decVideoRenderingFlag(this.videoScreen);
            if (this._redisplayMeshAfterVideoEnd) {
                this.mesh.visible = true;
                this.videoScreen.visible = false;
                this.videoScreenInit = false;
            }
            RenderingValidationSingleton.getInstance().invalidateOnce(); // make sure to render once

        }
    }

    pause = () => {
        this._isPlaying = false;
        if (this.mediaRecord.videoAutoPlay !== VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS) {

            this.videoMeshButtonPlayState();
        }
        if (!this.mediaRecord.isAudioPlayer) {
            RenderingValidationSingleton.getInstance().decVideoRenderingFlag(this.videoScreen);
        }
    }

    play = () => {
        if (!this.mediaRecord.isAudioPlayer) {
            this.initVideoScreen();
            RenderingValidationSingleton.getInstance().incVideoRenderingFlag(this.videoScreen);
        }
        this._isPlaying = true;
        if (this.mediaRecord.videoAutoPlay !== VIDEO_PLAYING_MODE.VIDEO_AUTO_PLAY_NO_BUTTONS) {

            this.videoMeshButtonPauseState();
        }
    }


    autoPlay = () => {

        const self = this;

        //this.initVideoScreen();
        this.videoElm.play();
    }


    initVideoScreen = () => {
        if (this.videoScreenInit) return;
        this.mesh.visible = false;
        this.videoScreen.visible = true;
        this.videoScreenInit = true;
    }


    playVideo = () => {

        console.log("playVideo is pressed");
        if (!this.mediaRecord.isAudioPlayer) {
            if (! this._canPlay) {
                console.log("Video is not ready to play");
                return;
            }
        }

        if (this.isPlaying) {
            console.log("calling playVideo but video is playing");
            return;
        }


        // this.initVideoScreen();
        console.log("calling playVideo ");
        this.videoElm.play();

    }

    PauseVideo = () => {
        if (!this.isPlaying) {
            console.log("calling PauseVideo but video is not plying")
            return;
        }
        console.log("calling PauseVideo ")
        this.videoElm.pause();

    }


    get isPlaying() {
        return this._isPlaying;
    }


    createVideoButton = (videoButtonPos, boxSize) => {

        const sprite = new THREE.Sprite(this.spriteMaterialPlay);
        const spriteScale = this.mediaRecord.videoButtonScale || (this.mediaRecord.isAudioPlayer ? DEFAULT_AUDIO_ANNOTATION_SCALE : DEFAULT_VIDEO_ANNOTATION_SCALE);
        // console.log("[VideoDisplay] ********** video spriteScale=",spriteScale,this.mediaId);
        sprite.scale.copy(spriteScale);
        sprite.userData = this.mediaRecord.paintingId;

        sprite.position.copy(videoButtonPos);
        sprite.layers.set(VIDEO_LAYER_CHANEL);
        return sprite;
    }

    createCss3dVideoPlayer(width, height) {
        const div = document.createElement('div');
        div.style.width = `${width}px`;
        div.style.height = `${height}px`;
        div.style.backgroundColor = '#000';
        const video = document.createElement('iframe');
        video.style.width = `${width}px`;
        video.style.height = `${height}px`;
        video.style.border = '0px';
        video.src = ['https://www.youtube.com/embed/', 'SJOz3qjfQXU', '?rel=0'].join('');


        div.appendChild(video);

        const object = new CSS3DObject(div);
        object.scale.set(.001, .001, 1);

        return object;

    }

    get mediaId() {
        return this._mediaId;
    }

    videoMeshButtonOnClick = () => {
        if (this.isPlaying) {
            //this._videoMeshButton.setStatePause();
            this.PauseVideo();
        }
        else {
           // this._videoMeshButton.setStatePlay();
            this.playVideo();
        }
    }

    videoMeshGroupButtonOnClick = (buttonState) => {
        if (buttonState === BUTTON_STATE_PAUSE) {

            if (this.isPlaying) {
                this.PauseVideo();
            }
        }
        else {
            if (!this.isPlaying) {
                this.playVideo();
            }
        }


    }

    videoMeshButtonPlayState = ()=> {
        if (this._useVideoMeshButton || this._useVideoGroupMeshButton) {
            if ( this._videoMeshButton)  this._videoMeshButton.setStatePlay(this.mediaRecord.paintingId);
        }
        else {

            if (this.mediaRecord.displayAudioSprite === 'Y') {
                this.videoButton.material = this.spriteMaterialPlay;
            }
        }
    }
    videoMeshButtonPauseState = ()=> {
        if (this._useVideoMeshButton || this._useVideoGroupMeshButton) {
            if (this._videoMeshButton) this._videoMeshButton.setStatePause(this.mediaRecord.paintingId);
        }
        else {
            if (this.mediaRecord.displayAudioSprite === 'Y') {
                this.videoButton.material = this.spriteMaterialPause;
            }
        }
    }

    getVideoMesh = () => {
        return this.mesh;
    }

    get videoScreenMaterial() {
        return this._videoScreenMaterial;
    }

    resetVideoSource = (src) => {
       // const videoURL = process.env.PUBLIC_URL + `/videos/${src}`;
        console.log("resetVideoSource - ",src);
        //this.videoElm.src = videoURL;
        this.videoElm.setAttribute("src", src);


    }

    resetVideoElement =(videoElement) => {

        if (isIOS) {

            // this.videoElm.onloadedmetadata = null;
            this.videoElm.removeEventListener("loadedmetadata",this.canPlay);

        } else {
            // console.log("[VideoDisplay]", this.videoElm, this.canPlay);
            // this.videoElm.oncanplay = null;
            this.videoElm.removeEventListener("canplay",this.canPlay);

        }

        this.videoElm.removeEventListener("ended",this.ended);
        this.videoElm.removeEventListener("pause",this.pause);
        this.videoElm.removeEventListener("play",this.play);


     /*   this.videoElm.onended = null;
        this.videoElm.onpause = null;
        this.videoElm.onplay = null;*/


        this.videoElm = document.getElementById(videoElement);

       /* if (isIOS) {

            this.videoElm.onloadedmetadata = this.canPlay;

        } else {
            // console.log("[VideoDisplay]", this.videoElm, this.canPlay);
            this.videoElm.oncanplay = this.canPlay;

        }
        this.videoElm.onended = this.ended;
        this.videoElm.onpause = this.pause;
        this.videoElm.onplay = this.play;*/

        if (isIOS) {

            //  this.videoElm.onloadedmetadata = this.canPlay;
            this.videoElm.addEventListener("loadedmetadata",this.canPlay);

        } else {
            // console.log("[VideoDisplay]", this.videoElm, this.canPlay);
            // this.videoElm.oncanplay = this.canPlay;
            this.videoElm.addEventListener("canplay",this.canPlay);

        }

        this.videoElm.addEventListener("ended",this.ended);
        this.videoElm.addEventListener("pause",this.pause);
        this.videoElm.addEventListener("play",this.play);

        this.videoElm.loop = this.mediaRecord.videoLoop;

       /* if (this.mediaRecord.videoSrcType === VIDEO_SRC_TYPE.FROM_FILE) {
            this.videoElm.src = this.mediaRecord.videoURL;
        }
        else if (this.mediaRecord.videoSrcType === VIDEO_SRC_TYPE.FROM_STREAM_HLS) {

            console.log("HLS pre-init");
            if (Hls.isSupported()) {
                console.log("HLS init",this.mediaRecord.videoStreamURL);
                const hls = new Hls();
                hls.loadSource(this.mediaRecord.videoStreamURL);
                hls.attachMedia(this.videoElm);
                const self = this;
                hls.on(Hls.Events.MANIFEST_PARSED, function() {
                    console.log("Hls.Events.MANIFEST_PARSED:",self.mediaRecord.videoStreamURL);
                    //self.videoElm.play();
                });
            }
            else {
                console.log("HLS is not supported");
            }
        }*/

    }

    resetVideoDisplayMaterialTexture = ()=> {
        this._videoScreenMaterial.map.dispose();
        this._videoScreenMaterial.dispose();

        const texture = new THREE.VideoTexture(this.videoElm);
        texture.minFilter = THREE.NearestFilter;
        texture.magFilter = THREE.NearestFilter;
        texture.encoding = this.mediaRecord.videoTextureEncoding;
        texture.format = THREE.RGBFormat;

      //  this._videoScreenMaterial.map = texture;

        const parameters = {map: texture, side: THREE.DoubleSide,name:`MeshBasic-${this._mediaId}`};

       // const geometry = new THREE.PlaneBufferGeometry(boxSize.x, boxSize.y);


        this._videoScreenMaterial = new THREE.MeshBasicMaterial(parameters);
        this.videoScreen.material =  this._videoScreenMaterial;
    }

    get canAutoPlay() {
        return this._canAutoPlay;
    }

    set canAutoPlay(value) {
        this._canAutoPlay = value;
    }
}

export default VideoDisplay;
